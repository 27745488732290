import "./home.scss";

import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/Navbar/Navbar";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import TextButton from "../../../../components/TextButton/TextButton";
import tickIcon from "../../../../assets/tick.svg";
import careers from "../../../../assets/careersAtOne.svg";
import exiciting from "../../../../assets/exciting.svg";
import tick from "../../../../assets/tickicon.svg";
import laptop from "../../../../assets/Laptop.png";
import title from "../../../../assets/title.svg";
import desc from "../../../../assets/hero_desc.svg";
import descMob from "../../../../assets/descMob.svg";
import whatsappIcon from "../../../../assets/whatsapp.png";
import titleMob from "../../../../assets/title_mob.svg";
import groupImgs from "../../../../assets/hero_group.svg";

import heroMobile from "../../../../assets/Animations/Hero_Mobile.gif";
import Lottie from "react-lottie";
import { Link, useNavigate } from "react-router-dom";

function Home({ howRef }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowheight, setWindowheight] = useState(window.innerHeight);
  const [ref1, setRef] = useState(howRef);
  const navigate = useNavigate();

  const heroMobileOpts = {
    // loop: false,
    // autoplay: true,
    animationData: heroMobile,
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = () => {
    window.location.assign("https://forms.gle/EQ8bHHBdHqiNbSwG7");
  };
  return (
    <div className="home">
      <div className="homeLeft">
        <img src={laptop} className="lap"></img>
        <div className="HL-lower">
          {/* <Lottie
              options={heroMobileOpts}
              height={305}
              width={300}
              className="images"
            /> */}
          {windowWidth > 768 && <img src={heroMobile} className="mob"></img>}
          {/* <div className="ex">Eg: Game to learn About Line Graphs</div> */}
        </div>
      </div>
      <div className="homeRight">
        {windowWidth <= 768 && <img className="title" src={titleMob}></img>}
        {windowWidth > 768 && <img className="title" src={title}></img>}
        {windowWidth <= 768 && <img className="desc" src={descMob}></img>}
        {windowWidth > 768 && <img className="desc" src={desc}></img>}

        <div className="bar"></div>
        <p className="desc2">Textbooks are digitised into:</p>

        <div className="btsDiv">
          <img src={groupImgs} className="grpImg"></img>
          <button
            className="btn"
            onClick={() => {
              navigate("/signup");
            }}
          >
            <Link to="/signup" className="lnk">
              START FREE TRIAL
            </Link>
          </button>
          <p className="more" to="">
            Learn More
          </p>
        </div>

        {windowWidth <= 768 && <img src={heroMobile} className="mob"></img>}
        {/* {
              windowWidth <= 768 &&  */}
        <p className="home-bttm">Eg: Game to learn About Line Graphs</p>
        {/* } */}
      </div>
      <div
        className="fab"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: 20,
          right: 20,
          width: 56,
          height: 56,
          borderRadius: "50%",
          backgroundColor: "transparent", // Adjust background color as needed
          color: "#fff", // Adjust text color as needed
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow for better visibility
          cursor: "pointer",
          zIndex: 1000, // Ensure the FAB is above other elements
        }}
        onClick={() => {
          window.open(
            "https://chat.whatsapp.com/DyXdQT8mb2kHTanlDkLHIk",
            "_blank"
          );
        }}
      >
        <img src={whatsappIcon} alt="" height={40} />
      </div>
    </div>
  );
}

export default Home;