import { type } from "@testing-library/user-event/dist/type"

export class Student {
    constructor({ id, fname, lname, email, phone, age, currentClass, goal, schoolName, city, state, schoolID, type }) {
        this.id = id
        this.fname = fname
        this.lname = lname
        this.email = email
        this.phone = phone
        this.age = age
        this.currentClass = currentClass
        this.goal = goal
        this.schoolName = schoolName
        this.city = city
        this.state = state
        this.schoolID = schoolID
        this.type = type
    }
    toString() {
        return this.id + ', ' + this.fname + ', ' + this.lname + ', ' + this.email + ', ' + this.phone + ', ' + this.age + ', ' + this.currentClass + ', ' + this.goal + ', ' + this.schoolName + ', ' + this.city + ', ' + this.state + ', ' + this.schoolID + ', ' + this.type;
    }
}

// Firestore data converter
export const studentConverter = {
    toFirestore: (student) => {
        return {
            id: student.id ?? "NA",
            fname: student.fname ?? "NA",
            lname: student.lname ?? "NA",
            email: student.email ?? "NA",
            phone: student.phone ?? "NA",
            age: student.age ?? "NA",
            currentClass: student.currentClass ?? "6",
            goal: student.goal ?? "NA",
            schoolName: student.schoolName ?? "NA",
            city: student.city ?? "NA",
            state: student.state ?? "NA",
            schoolID: student.schoolID ?? "NA",
            type: student.type ?? "free",
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        const student = new Student({
            id: data.id,
            fname: data.fname, 
            lname: data.lname, 
            email: data.email, 
            phone: data.phone, 
            age: data.age, 
            currentClass: data.currentClass,
            goal: data.goal,
            schoolName: data.schoolName, 
            city: data.city, 
            state: data.state,
            schoolID: data.schoolID,
            type: data.type,
        }
        );
        return student;
    }
};
