import * as React from "react";
import "./tabSelector.scss";

export const TabSelector = ({
  isActive,
  children,
  onClick,
}) => (
  <button
    className={` tabs ${
      
      isActive
        ? "active"
        : "inactive"
    }`}
    onClick={onClick}
    id='button'
  > 
    
    {children}
  </button>
);