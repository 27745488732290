import React, { useContext, useEffect, useState, useRef } from 'react';
import './quiz.scss';
import { Authcontext } from '../../../contextProvider';
import YouTube from 'react-youtube';
import { collection, orderBy, query, updateDoc, where } from 'firebase/firestore';
import headerImg from '../../../assets/checkpoint_header_img.png'
import { getDocs, doc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import ProgressBar from '../progressBar/progressBar';
import SideBar from '../SideBar/SideBar';
import NavBarMob from '../SideBar/NavBarMob';
import { useNavigate } from 'react-router-dom';

function Quiz(props) {
  const { currentUser } = useContext(Authcontext);
  const { currentChapter, setCC } = useContext(Authcontext);
  const {currentTest,setTest} = useContext(Authcontext)
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const [userData, setUC] = useState([]);
  const [score, setScore] = useState();
  const [content, setContent] = useState(props.content);
  const navigate = useNavigate()

  const [answers, setAnswers] = useState([]);
  const [showAns, setShow] = useState(false);

  const userDataRef = collection(db, 'user_data');

  useEffect(()=>{
    if(!currentTest){
      navigate('/library');
    }
    console.log(currentTest)
  },[])
  const handleOptionSelect = (questionIndex, optionIndex) => {
    if (!showAns) {
      const updatedAnswers = [...answers];
      updatedAnswers[questionIndex] = optionIndex;
      setAnswers(updatedAnswers);

    }

  };

  const calculateScore = () => {
    let score = 0;
    currentTest.ques.forEach((question, index) => {
        console.log(answers[index])
        console.log(question.answer)
      if (answers[index] === question.answer) {
        score++;
      }
    });
    return score;
  };

  const handleSubmit = async() => {
    let score = calculateScore();
    setScore(calculateScore());
    setShow(true);
    setAnswers([]);
    let temp = userData.testResults
    temp = [...temp,{id:currentTest.id,test_name:currentTest.test_name,score:score}]
    if(temp.length > 0 ){
      await updateDoc(doc(db, "user_data", currentUser.uid), {
          "testResults":temp,
      })
    }

  };
  const HandleBack = () =>{
    navigate('/library');
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      const q1 = query(userDataRef, where('id', '==', `${currentUser.uid}`));
      let temp1 = [];
      const querySnapShot1 = await getDocs(q1);
      try {
        querySnapShot1.forEach((doc) => {
          temp1.push(doc.data());
        });
        setUC(temp1[0]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserInfo();
  }, []);

    return(
        <div className="checkpoint">
        <img src={headerImg}></img>
        <div className="main">
          <h2 className="heading">Questions</h2>
          {currentTest.ques.map((question, questionIndex) => (
            <div className="question-box" key={questionIndex}>
              <p className="question">
                {questionIndex + 1}. {question.question}
              </p>
              <div className="options">
                {question.opts.map((option, optionIndex) => (
                  <p
                    key={optionIndex}
                    onClick={() => handleOptionSelect(questionIndex, optionIndex)}
                    className={`option ${
                      answers[questionIndex] === optionIndex && !showAns ? 'selected' : ''
                    } ${
                      showAns && question.answer === optionIndex ? 'correct' : ''
                    }`}
                  >
                    {option}
                  </p>
                ))}
              </div>
              {showAns && (
                <p className="answer">Correct Answer: {question.answer}</p>
              )}
            </div>
          ))}
          {!showAns && (
            <button onClick={handleSubmit} className="submit-btn">
              Submit
            </button>
          )}
          {showAns && (
            <div className="final">
              <button className="nav-btn" onClick={HandleBack}>
                Next →
              </button>
              <p>
                Your Total score is {score}/{currentTest.ques.length}
              </p>
            </div>
          )}
        </div>
      </div>
    )
}
export default Quiz