
import './sideBar.scss'
import logo from '../../../assets/sidebar-logo.svg'
import libratyIcon from '../../../assets/library.svg'
import dashboardIcon from '../../../assets/dashboardicon.svg'
import profilelogo from '../../../assets/profileicon.svg'
import logouticon from '../../../assets/logouticon.png'
import { Link,useLocation } from 'react-router-dom'
import React from 'react'
import { signOut } from 'firebase/auth'
import { auth } from '../../../services/firebase'
import { useState,useEffect } from 'react'


function SideBar({ children,p }) {

    const location = useLocation()
    const [currentRoute, setCurrentRoute] = useState('');

    useEffect(() => {
        setCurrentRoute(location.pathname);
      }, [location]);
    
    const handleSignOut = () => {
        signOut(auth)
    }


    return (
        <>
            <div className='sidebar'>
                <Link className='logo' to='/'>
                    <img className='logo' src={logo} alt="one logo" />
                </Link>
                {/* filter: brightness(1.2) contrast(1.2) hue-rotate(40deg); */}

                <div>
                {
                    !p && 
                    <Link className='nav-button' to="/dashboard">
                        {currentRoute == '/dashboard' &&
                            <>
                            <img src={dashboardIcon} alt=""></img>
                            <p style={{color:'transparent',backgroundColor:'green',borderRadius:'15px',color:'#1c1c1c',fontWeight:'600'}}>Dashboard</p>
                            </>
                        }
                        {currentRoute != '/dashboard' && 
                            <>
                            <img src={dashboardIcon} alt="" ></img>
                            <p>Dashboard</p>
                            </>
                        }
                    </Link>
                }
                {
                    !p && 
                        <Link className='nav-button' to="/library">
                            {currentRoute == '/library' &&
                                <>
                                    <img src={libratyIcon} alt=""></img>
                                    <p style={{color:'transparent',backgroundColor:'orange',borderRadius:'15px',color:'#1c1c1c',fontWeight:'600'}}>Library</p>
                                </>

                            }
                            {/* style={{filter:'sepia(20) saturate(65) hue-rotate(0deg)'}} */}
                            {/* style={{filter:' brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)'}} */}
                            {currentRoute != '/library' && 
                                <>
                                    <img src={libratyIcon} alt="" style={{filter:'grayscale(100%)'}}></img>
                                    <p>Library</p>
                                </>
                            }
                        </Link>
                }
                {
                    !p && 
                        <Link className='nav-button' to="/profile">
                            {currentRoute == '/profile' &&
                                <>
                                <img src={profilelogo} alt="" style={{filter: `grayscale(0) sepia(100%) hue-rotate(-100deg)`}}></img>
                                <p style={{color:'transparent',backgroundColor:'red',borderRadius:'15px',color:'#1c1c1c',fontWeight:'600'}}>Profile</p>
                                </>
                            }
                            {currentRoute != '/profile' && 
                                <>
                                    <img src={profilelogo} alt=""></img>
                                    <p>Profile</p>
                                </>
                            }
                        </Link>
                }

                </div>
                <Link className='logout-button' onClick={handleSignOut}>
                    <img src={logouticon} alt="" />
                    <p>Logout</p>
                </Link>
            </div>
            <div>{children}</div>
        </>
    )
}

export default SideBar
