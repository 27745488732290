import React from "react";
import { Link } from "react-router-dom";
import "./BlogAppBar.scss";
import logo from '../../logo.svg';

const BlogAppBar = () => {
  return (
    <div className="app-bar">
      <Link className="logo-container" to="/">
        <img className="logo" src={logo} alt="one logo" />
      </Link>
      <h1 className="page-title">Blogs</h1>
      <div className="spacer"></div>
    </div>
  );
};

export default BlogAppBar;
