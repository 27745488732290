import React, { useContext, useEffect, useState } from 'react'
import SideBar from '../SideBar/SideBar'
import './library.scss'
import img1 from '../../../assets/lib_img1.svg'
import img2 from '../../../assets/lib_img2.svg'
import newspaperIcon from '../../../assets/library-img-1.svg'
import newspaperIcon2 from '../../../assets/library-img-2.svg'
import popUpImg1 from '../../../assets/Group.svg'
import track1 from '../../../assets/track_odd.svg'
import track2 from '../../../assets/track_even.svg'
import NavBarMob from '../SideBar/NavBarMob'
import { auth, db } from '../../../services/firebase'
import { collection, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getDocs, doc } from "firebase/firestore";
import { Authcontext } from '../../../contextProvider'
import { useNavigate } from 'react-router-dom'
import ProgressBar from '../progressBar/progressBar'
import { onAuthStateChanged ,sendEmailVerification,signOut } from 'firebase/auth'

function Library() {
  const [chapters,setChaps] = useState([]) //Combined chapters data 
  const {ch,setch} = useContext(Authcontext)  // All chapters from chapters collection
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const {currentUser} = useContext(Authcontext)
  const [userChaps,setUC] = useState([]) // User chapters data 
  const {currentChapter,setCC} = useContext(Authcontext)
  const [userData,setUD] = useState([])
  const [compPopUp,setCPU] = useState(true);

  const [testVis,setTestVis] = useState(false)
  const [notesVis,setnotesVis] = useState(false)
  // const [notes,setNotes] = useState([{noteId:1,noteLink:'#',noteName:'Chapter 0'},{noteId:2,noteLink:'#',noteName:'Chapter 1'}])
  // const [tests,setTests] = useState([{testId:1,testName:'Chapter 0'},{testId:2,testName:'Chapter 1'}])

  const [view,setView] = useState('hidden')
  const [completion,setComp] = useState(0)


  const chaptersRef = collection(db, "chapters");
  const userDataRef = collection(db, "user_data");
  const userRef = collection(db,'users')
  const navigate = useNavigate();
  const [user,setUser] = useState()
  

  useEffect(()=>{
    if(currentUser){
      if(!currentUser.emailVerified){
        signOut(auth);
      }
    }
    const fetchUserChaps =async()=>{
      const q1 = query(userRef,where('id','==',`${currentUser.uid}`))
      let temp1 = []
      const querySnapShot1 = await getDocs(q1)
      try {
          querySnapShot1.forEach((doc) => {
              temp1.push(doc.data())
          })
          setUser(temp1[0])
          console.log(temp1)
      } catch (err) {
          console.log(err)
      }
    }
    fetchUserChaps()
  },[])
  useEffect(()=>{
    if(user && user.age == 'NA'){
      navigate('/accountsetup');
    }
  },[user])


  // const fetchChapters = async()=>{
  //   const q = query(chaptersRef,orderBy('no'))
  //   const temp = []
  //   const querySnapShot = await getDocs(q)
  //   try {
  //       querySnapShot.forEach((doc) => {
  //           temp.push(doc.data())
  //       })
  //       setch(temp)

  //       setUD(temp[0])
  //       console.log(temp[0])
  //   } catch (err) {
  //       console.log(err)
  //   }
  // }

  useEffect(()=>{
    const fetchUserChaps =async()=>{
      const q1 = query(userDataRef,where('id','==',`${currentUser.uid}`))
      let temp1 = []
      const querySnapShot1 = await getDocs(q1)
      try {
          querySnapShot1.forEach((doc) => {
              temp1.push(doc.data())
          })
          setUC(temp1[0].enrolled_Chapters)
          setComp(temp1[0].completion)
      } catch (err) {
          console.log(err)
      }
    }
    fetchUserChaps()
  },[ch])

  useEffect(()=>{
    let initialState = [];
    // let temp = ch;
    if(userChaps.length == 0){
      for(let j=0;j<ch.length;j++){
        initialState = [...initialState,{chapter:ch[j],status:0}]
      }
    }
    else{
      let temp = [...ch];
      console.log(temp[2])
      let its = [];
      for(let i=0;i<temp.length;i++){
        for(let j=0;j<userChaps.length;j++){
          if(userChaps[j].chap_id == temp[i].chap_id){
            // console.log(userChaps[j].chap_id) 
            // console.log(temp[i].chap_id)
            initialState = [...initialState,{chapter:temp[i],status:userChaps[j].status}]
            its = [...its,temp[i].chap_id];
            break;
          }
        }
      }
      for(let i = 0;i<its.length;i++){
        for(let j = 0 ; j<temp.length;j++){
          if(temp[j].chap_id == its[i]){
            temp.splice(j,1);
            console.log(temp.length)
            break
          }
        }
      }
        for(let i=0;i<temp.length;i++){
          initialState = [...initialState,{chapter:temp[i],status:0}]
          // temp.splice(its[i],1);
        }
    }
    // setChaps(initialState.sort((a, b) => {
    //   const nameA = a.chap_id;
    //   const nameB = b.chap_id;
    //   console.log(initialState)
    //   console.log('h')
    //   if (nameA < nameB) {
    //     return -1;
    //   } else if (nameA > nameB) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // }));
    setChaps(initialState.sort((a, b) => {
      const numberA = a.chapter.no;
      const numberB = b.chapter.no;
    
      return numberA - numberB; // Compare numeric values
    }));
    
  },[userChaps])



  const HandleStart = async (chapter) =>{
    const temp = [...userChaps,{chap_id:`${chapter.chap_id}`,status:1,"checkpointResults":[],completion:0}];
    if(temp.length > 0 ){
      await updateDoc(doc(db, "user_data", currentUser.uid), {
          "current_chap":{chapter},
          "enrolled_Chapters":temp,
      })
    }
    setCC(chapter);
    localStorage.setItem('currentChapter', JSON.stringify(chapter));
    navigate('/chapters')
  }
  const HandleResume = async (chapter) =>{
      await updateDoc(doc(db, "user_data", currentUser.uid), {
        "current_chap":{chapter},
    })
    setCC(chapter);
    localStorage.setItem('currentChapter', JSON.stringify(chapter));
    navigate('/chapters')

    
  }
  const HandleStartAgain = async(chapter)=>{
    let temp = userChaps
    for(let i=0;i<temp.length;i++){
      if(temp[i].chap_id == `${chapter.chap_id}`){
          temp[i].status = 1;
          temp[i].completion = 0;
      }
    }
    if(temp.length > 0){
      await updateDoc(doc(db, "user_data", currentUser.uid), {
          "current_chap":{chapter},
          "enrolled_Chapters":temp,
      })
    }
    setCC(chapter);
    localStorage.setItem('currentChapter', JSON.stringify(chapter));
    navigate('/chapters')
  }

  const HandleChapView =()=>{
    if(view == 'visible'){
      setView('hidden')
    }
  }
  const HandleChapView2 =()=>{
    if(view == 'hidden'){
      setView('visible')
    }
  }

  return (
        <div className='library'>
          {
            windowWidth >= 768 &&
            <SideBar p = {false}/>
          }
          {
            windowWidth < 768 && 
            <NavBarMob p = {false}/>
          }
          {
            compPopUp && windowWidth < 1024 && 
            <div className='mainP'>
              <div className='contentP'>
                <p className='heading'>
                Desktop Compatibility Only
                </p>
                <img src={popUpImg1}></img>
                <p className='p1'>
                Please note that our games & experiments for chapters 1,2,3,4 & 8 are optimized for desktop play and are not compatible with mobile or touch devices. Kindly access our website on your desktop or laptop to enjoy the full gaming experience.
                </p>
                <p className='p2'>You can still enjoy our animated videos and tackle checkpoint questions on your mobile.</p>
                <button onClick={()=>{setCPU(false)}}>Proceed Anyways</button>
              </div>
            </div>
          }
          <div className='Chapters'>
            {
                windowWidth < 768 && view == 'hidden' &&
                <div className='NavbarMob2'>
                  <button onClick={()=>{HandleChapView()}}>Chapters</button>
                  <button onClick={()=>{HandleChapView2()}} style={{backgroundColor:'#1C1C1C'}}>Extras</button>
                </div>
                
            }
            {
                windowWidth < 768 && view == 'visible' &&
                <div className='NavbarMob2'>
                  <button onClick={()=>{HandleChapView()}} style={{backgroundColor:'#1C1C1C'}}>Chapters</button>
                  <button onClick={()=>{HandleChapView2()}}>Extras</button>
                </div>
                
            }
            {
              chapters.length == 0 && 
              <p className='StayTuned'> Stay tuned, More Content coming soon!</p>
            }
            {chapters.length > 0 &&
              chapters.map((chapter,i)=>{
                if((i+1)%2!=0){ 
                  return(
                    <div className='chapter'>
                      <img src={chapter.chapter.bannerURL} className='banner'></img>
                      {
                        i!=chapters.length-1 && 
                        <img src={track1} className='track2'></img>
                      }
                      <div className='content'>
                        <div className='cName'>{chapter.chapter.chapter_name}</div>
                        <div className='cDesc'>{chapter.chapter.chapter_desc}</div>
                      </div>
                      { user && user.type !== 'paid' && !([0, 1, 3, 9].includes(chapter.chapter.no)) &&
                        <button className='StartChap' style={{marginLeft:'3%'}}>🔐</button>

                      }
                      { chapter.status == 0 && user && (user.type === 'paid' || ([0, 1, 3, 9].includes(chapter.chapter.no))) &&
                        <button className='StartChap' style={{marginLeft:'3%'}}  onClick={()=>{HandleStart(chapter.chapter)}}>Start →</button>

                      }
                      {
                        chapter.status  == 1 && user && (user.type === 'paid' || ([0, 1, 3, 9].includes(chapter.chapter.no))) &&
                        <button className='ResumeChap' style={{marginLeft:'3%'}}  onClick={()=>{HandleResume(chapter.chapter)}}>➚ Resume</button>
                      
                      }
                      {
                        chapter.status == 2 && user && (user.type === 'paid' || ([0, 1, 3, 9].includes(chapter.chapter.no))) &&
                        <button className='RestartChap' style={{marginLeft:'3%'}} onClick={()=>{HandleStartAgain(chapter.chapter)}}>→ <br></br> Start <br></br> Again</button>
                      
                      }
                    </div>
                  )
                }
                return(
                  <div className='chapter'>
                    { chapter.status  == 0 && user && (user.type === 'paid' || ([0, 1, 3, 9].includes(chapter.chapter.no))) &&
                      <button className='StartChap' style={{marginLeft:'3%'}} onClick={()=>{HandleStart(chapter.chapter)}}>Start →</button>
                    }
                    {
                      chapter.status == 1 && user && (user.type === 'paid' || ([0, 1, 3, 9].includes(chapter.chapter.no))) &&
                      <button className='ResumeChap' style={{marginLeft:'3%'}}  onClick={()=>{HandleResume(chapter.chapter)}}>➚ Resume</button>
                    
                    }
                    {
                      chapter.status == 2 && user && (user.type === 'paid' || ([0, 1, 3, 9].includes(chapter.chapter.no))) &&
                      <button className='RestartChap' style={{marginLeft:'3%'}} onClick={()=>{HandleStartAgain(chapter.chapter)}}>→ <br></br> Start <br></br> Again</button>
                    }
                    { user && user.type !== 'paid' && !([0, 1, 3, 9].includes(chapter.chapter.no)) &&
                      <button className='StartChap' style={{marginLeft:'3%'}}>🔐</button>

                    }
                    <div className='content'>
                      <div className='cName' style={{textAlign:'right'}}>{chapter.chapter.chapter_name}</div>
                      <div className='cDesc' style={{textAlign:'right'}}>{chapter.chapter.chapter_desc}</div>
                    </div>  
                    <img src={chapter.chapter.bannerURL} style={{marginLeft:'2%'}} className='banner'></img>
                    {
                      i!=chapters.length-1 && 
                      <img src={track2} className='track2'></img>
                    }
                  </div>
                )
            })
            }
          </div>
            {
              windowWidth >= 768 && chapters.length > 0 &&
              <ProgressBar percentage={completion} total={chapters.length} userData={userData} chapters = {ch}/>
            }
            {
              windowWidth < 768 &&
              <div className='progressTabMob' style={{visibility:`${view}`}}>
                <div className='container-1'>
                    <h5>Your total progress</h5>
                    <p className='per'><p style={{marginLeft:`${(completion/chapters.length)*100}%`}}> {Math.floor((completion/chapters.length)*100)}% <b>|</b></p></p>
                    {/* <div className='progressPopUp'>75%</div> */}
                    <div className='progressBar'>
                        <div className='PBinner' style={{width:`${(completion/chapters.length)*100}%`}}></div>
                    </div>
                    
                </div>
                <div className='container-2'>
                    {
                      !testVis &&
                      <div>
                        <h5>Unfinished Tests</h5>
                        <img src={newspaperIcon} style={{marginTop:'1vh'}}></img>
                        <p className='desctext'>Finishing your test shows your superpower of determination and courage!</p>
                              {
                                currentChapter && 
                                <button className='Lib-btn' onClick={()=>{setTestVis(true)}}>Tests <b>→</b></button>
                              }
                      </div>
                    }
                    {
                      testVis &&
                      <div className='viewInfo'>
                        <button className='back' onClick={()=>{setTestVis(false)}}>Back</button>
                        {
                          ch.map((chapter)=>(
                                <div className='component'>
                                  <p>{chapter.chapter_name}</p>
                                  <a href={`${chapter.testLink}`}>Start</a>
                                </div>
                          ))
                        }
                          </div> 
                    }
                </div>
                <div className='container-2' style={{border:'none'}}>
                {
                      !notesVis && 
                      <>
                        <h5>Notes</h5>
                        {
                          windowWidth > 380 && 
                          <img src={newspaperIcon2} style={{top:'70%',height:'10%',left:'-2%'}}></img>
                        }
                        {
                          windowWidth <=380  &&
                          <img src={newspaperIcon2} id='img2'></img>
                        }
                        <p className='desctext'>Comprehensive notes for all chapters to revise, learn and enjoy! </p>
                            {
                              currentChapter && 
                              <button className='Lib-btn' onClick={()=>setnotesVis(true)}>Download <b>→</b></button>
                            }
                      </>
                    }
                    {
                      notesVis && 
                      <div className='viewInfo'>
                        <button className='back' onClick={()=>{setnotesVis(false)}}>Back</button>

                        {
                          ch.map((chapter)=>(
                                <div className='component'>
                                  <p>{chapter.chapter_name}</p>
                                  <a href={`${chapter.notesLink}`}>Download</a>
                                </div>
                          ))
                        }
                      </div>
                      
                    }
                </div>
              </div>
            }
          {/* <ProgressBar percentage={5} total={10} userData ={userChaps}/> */}
        </div>
  )
}

export default Library