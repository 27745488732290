import React, { useState } from 'react'
import "./reports.scss"
import { Link, useNavigate } from 'react-router-dom'
import digitalTextIcon from '../../../../assets/digital-textbook.svg'
import reportsBar from '../../../../assets/reports-bar.svg'
import experienceButton from '../../../../assets/experience-button.svg'
import experienceButton1 from '../../../../assets/experience-button1.png'

function Reports() {
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const navigate = useNavigate()

  return (
    <div className='reports'>
      <div className='left-content'>
        <div className='card'>
          <div className='title'>Reports are created using our advanced ML <i>algorithms</i>.</div>
            <div className='row'>
                <img src={reportsBar} className="reports-bar" alt="" />
                <div className='column'>
                    <p className='subtitle'>Statistics created per chapter for the entire class & mailed to respective teachers.</p>
                    <p className='subtitle2'>This tool facilitates communication with teachers regarding the overall direction of the class.</p>
                    <p className='subtitle1'>Statistics created per chapter for students and parents.</p>
                    <p className='subtitle2'>This tool serves as an excellent way to assess a student's strengths and weaknesses, while also providing insight into the challenging areas where they may encounter difficulties.</p>
                </div>
            </div>
         </div>
      </div>
      <div className='right-content'>
        <img src={digitalTextIcon} className="startup-logo" alt="" />
        { windowWidth > 700 && windowWidth <1024 &&
          <button className='btn' onClick={()=>{navigate('/signup')}} style={{backgroundColor:'black',color:'white'}}> I want to Start Now</button>
        }
        {(windowWidth < 700 || windowWidth >1024) &&
          <button className='btn' onClick={()=>{navigate('/signup')}} > I want to Start Now</button>
        }
      </div>
    </div>
  )
}

export default Reports