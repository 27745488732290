  import gsap from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
  import { useLayoutEffect, useRef , useState, useEffect } from "react";
  import "./homepage.scss";
  import Home from "./sections/Home/Home";
  import Why from "./sections/Why/Why";
  import rocket from '../../assets/rocket.svg'

  import Recognition from "./sections/Recognition/Recognition";
  import HowLeft from "./sections/HowOneWorks/HowLeft/HowLeft";
  import HowRight from "./sections/HowOneWorks/HowRight/HowRight";
  import howWork2 from '../../assets/Animations/Games.gif'
  import howWork1 from '../../assets/Animations/Animations.gif'
  import howWork3 from '../../assets/Animations/Exp.gif'
  import howWork4 from '../../assets/Animations/Reports.gif'
  import ncertText from '../../assets/ncert (4).png'
  import ncertMob from '../../assets/text-ncert-mob.png'
  import ncert1 from '../../assets/ncert (3).png'
  import ncert2 from '../../assets/ncert (2).png'
  import ncert3 from '../../assets/ncert (1).png'
  import Reports from "./sections/Reports/Reports";
  import MentalHealth from "./sections/MentalHealth/MentalHealth";
  import WhatIsOne from "./sections/WhatIsOne/WhatIsOne";
  import weCareText from "../../assets/we-care-text.svg"
  import WeAreOne from "./sections/WeAreOne/WeAreOne";
  import Pricing from "./sections/Pricing/Pricing";
  import Footer from "./sections/Footer/Footer";
import Discover from "./sections/discover/discover";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";


  gsap.registerPlugin(ScrollTrigger);


  export default function Homepage() {

    const [windowWidth, setWindowWidth] = useState(window.outerWidth);

    useEffect(() => {
      function handleResize() {
        setWindowWidth(window.outerWidth);
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const component = useRef();
    const slider = useRef();
    const recognition = useRef();
    const how = useRef();
    const report = useRef();
    const mentalHealth = useRef();
    const mentalHealthTitle1 = useRef();
    const whatOne = useRef();
    const weAreOne = useRef();
    const rocketRef = useRef();
    const pricing = useRef();
    const discoverRef = useRef()
    const footerRef = useRef()


    useLayoutEffect(() => {
      if(windowWidth <1024 && windowWidth >= 768){
        let ctx = gsap.context(() => {
          let panels = gsap.utils.toArray(".whysection");
          
           
    
            
            gsap.to(how.current, {
              ease: "none",
              scrollTrigger: {
                trigger: how.current,
                scrub: 1,
                end: () => `+=${how.current.offsetHeight - slider.current.offsetHeight}`,
                invalidateOnRefresh: true,
              },
            });
            gsap.to(recognition.current, {
              ease: "none",
              scrollTrigger: {
                trigger: recognition.current,
                scrub: 1,
                end: () => `+=${recognition.current.offsetHeight - slider.current.offsetHeight}`,
                invalidateOnRefresh: true,
              },
            });
        
    
            // gsap.to(mentalHealth.current, {
            //   ease: "none",
            //   scrollTrigger: {
            //     trigger: mentalHealth.current,
            //     scrub: 3,
            //     pin: ".mentalHealth",
            //     end: () => `+=${mentalHealth.current.offsetHeight * 2}`,
            //     invalidateOnRefresh: true,
            //   },
            // });
    
            gsap.to(report.current, {
              ease: "none",
              scrollTrigger: {
                trigger: report.current,
                scrub: 1,
                end: () => `+=${report.current.offsetHeight - slider.current.offsetHeight}`,
                invalidateOnRefresh: true,
              },
            });
            // const mentalTexts = gsap.utils.toArray(".mental-health__title");
    
            // const tl = gsap.timeline({
            //   scrollTrigger: {
            //     trigger: mentalHealth.current,
            //     start: "center center",
            //     end: () => `+=${mentalHealth.current.offsetHeight * 2}`,
            //     scrub: 1,
            //   },
            // });
            // tl.set(mentalTexts[1], { autoAlpha: 0, display: 'none' });
            // tl.to(mentalTexts[0], { autoAlpha: 0, display: 'none' });
            // tl.to(
            //   mentalTexts[1],
            //   { autoAlpha: 1, display: 'block' },
            // );
            
            // gsap.to(weAreOne.current, {
            //   rotate: -18,
            //   x: 100,
            //   y: -100,
            //   scrollTrigger: {
            //     trigger: weAreOne.current,
            //     start: "top",
            //     end: () => `+=${weAreOne.current.offsetHeight * 1.5}`,
            //     pin: weAreOne.current,
            //     scrub: true,
            //     invalidateOnRefresh: true,
            //   }
            // })
    
    
            gsap.to(pricing.current, {
              ease: 'none',
              padding:  (windowWidth < 768)? "0px 15px" :"0px 40px",
              scrollTrigger: {
                trigger: pricing.current,
                start: (windowWidth < 768)? "bottom center" :"center",
                end:  (windowWidth < 768)? "center" :"center",
                scrub: 1,
              },
            });
            const rocketTimeline = gsap.timeline({
              scrollTrigger: {
                trigger: slider.current,
                start: `top top+=${window.innerHeight * 1}`,
                end: `bottom bottom-=${window.innerHeight * 0.1}`,
                scrub: 2,
                onEnterBack: ({progress}) => {
                  const scrollTarget = window.innerHeight;
                  if(progress < 0.75){
                    window.scrollTo({ top: scrollTarget, behavior: 'smooth' });
                  }
                },

              },
            });
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: 0,
              y: -50,
              rotate: 30,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: 0,
              y: -25,
              rotate: 80,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: 0,
              y: 0,
              rotate: 100,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: 0,
              y: 500,
              rotate: 150,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: -100,
              y: 1000,
              rotate: 180,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: -175,
              y: 1500,
              rotate: 150,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: -225,
              y: 1750,
              rotate: 100,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: -175,
              y: 1875,
              rotate: 60,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: -100,
              y: 2000,
              rotate: 45,
            })
            rocketTimeline.to(rocketRef.current, {
              ease: "power0",
              x: 0,
              y: 2150,
              rotate: 0,
            })
  
            
          }, component);
          return () => ctx.revert();
    

      }
      else if(windowWidth <768 )
      {
        let ctx = gsap.context(() => {
        const height = slider.current.offsetHeight
         
  
          
          gsap.to(how.current, {
            ease: "none",
            scrollTrigger: {
              trigger: how.current,
              scrub: 1,
              end: () => `+=${how.current.offsetHeight - slider.current.offsetHeight}`,
              invalidateOnRefresh: true,
            },
          });
          gsap.to(recognition.current, {
            ease: "none",
            scrollTrigger: {
              trigger: recognition.current,
              scrub: 1,
              end: () => `+=${recognition.current.offsetHeight - slider.current.offsetHeight}`,
              invalidateOnRefresh: true,
            },
          });
      


  
          // gsap.to(mentalHealth.current, {
          //   ease: "none",
          //   scrollTrigger: {
          //     trigger: mentalHealth.current,
          //     scrub: 3,
          //     pin: ".mentalHealth",
          //     end: () => `+=${mentalHealth.current.offsetHeight * 2}`,
          //     invalidateOnRefresh: true,
          //   },
          // });
  
          gsap.to(report.current, {
            ease: "none",
            scrollTrigger: {
              trigger: report.current,
              scrub: 1,
              end: () => `+=${report.current.offsetHeight - slider.current.offsetHeight}`,
              invalidateOnRefresh: true,
            },
          });
          // const mentalTexts = gsap.utils.toArray(".mental-health__title");
  
          // const tl = gsap.timeline({
          //   scrollTrigger: {
          //     trigger: mentalHealth.current,
          //     start: "center center",
          //     end: () => `+=${mentalHealth.current.offsetHeight * 2}`,
          //     scrub: 1,
          //   },
          // });
          // tl.set(mentalTexts[1], { autoAlpha: 0, display: 'none' });
          // tl.to(mentalTexts[0], { autoAlpha: 0, display: 'none' });
          // tl.to(
          //   mentalTexts[1],
          //   { autoAlpha: 1, display: 'block' },
          // );
          
          // gsap.to(weAreOne.current, {
          //   rotate: -18,
          //   x: 100,
          //   y: -100,
          //   scrollTrigger: {
          //     trigger: weAreOne.current,
          //     start: "top",
          //     end: () => `+=${weAreOne.current.offsetHeight * 1.5}`,
          //     pin: weAreOne.current,
          //     scrub: true,
          //     invalidateOnRefresh: true,
          //   }
          // })
  
  
          gsap.to(pricing.current, {
            ease: 'none',
            padding:  (windowWidth < 768)? "0px 15px" :"0px 40px",
            scrollTrigger: {
              trigger: pricing.current,
              start: (windowWidth < 768)? "bottom center" :"center",
              end:  (windowWidth < 768)? "center" :"center",
              scrub: 1,
            },
          });


          const rocketTimeline = gsap.timeline({
            scrollTrigger: {
              trigger: slider.current,
              start: `top top+=${window.innerHeight * 1.3}`,
              end: `bottom bottom-=${window.innerHeight * 0.5}`,
              scrub: 1,
              onEnterBack: () => {
                const scrollTarget = window.innerHeight;
                window.scrollTo({ top: scrollTarget, behavior: 'smooth' });
              },
            },
          });
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            // y: '-3vh',
            y: `-${0.03*height}`,
            rotate: 30,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            // y: '-5vh',
            y: `-${0.06*height}`,
            rotate: 80,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            // y: 0,
            y: `0%`,
            rotate: 100,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            // y: '15vh',
            y: `${0.14*height}`,
            rotate: 150,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: -50,
            // y: '30vh',
            y: `${0.3*height}`,
            rotate: 180,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: -100,
            // y: '45vh',
            y: `${0.45*height}`,
            rotate: 150,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: -150,
            // y: '65vh',
            y: `${0.6*height}`,
            rotate: 100,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: -100,
            // y: '85vh',
            y: `${0.7*height}`,
            rotate: 60,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: -50,
            // y: '105vh',
            y: `${0.75*height}`,
            rotate: 45,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            // y: '135vh',
            y: `${0.88*height}`,
            rotate: 0,
          })

          // if(windowWidth <= 380){
            // rocketTimeline.to(rocketRef.current, {
            //   ease: "power0",
            //   x: 0,
            //   y: '135vh',
            //   rotate: 0,
            // })
          // }
          // else if(windowWidth <= 400 && windowWidth > 380){
          //   rocketTimeline.to(rocketRef.current, {
          //     ease: "power0",
          //     x: 0,
          //     y: '130vh',
          //     rotate: 0,
          //   })
          // }
          // else if(windowWidth > 400 && windowWidth <=440){
          //   rocketTimeline.to(rocketRef.current, {
          //     ease: "power0",
          //     x: 0,
          //     y: '125vh',
          //     rotate: 0,
          //   })
          // }

          
        }, component);
        return () => ctx.revert();
  
      }
      else{
        let ctx = gsap.context(() => {
          let panels = gsap.utils.toArray(".whysection");
          gsap.to(panels, {
            xPercent: -85 * (panels.length - 1),
            ease: "none",
            scrollTrigger: {
              trigger: slider.current,
              pin: true,
              scrub: 1,
              end: () => "+=" + slider.current.offsetWidth*2,
            }
          });
        
          const rocketTimeline = gsap.timeline({
            scrollTrigger: {
              trigger: slider.current,
              start: "end",
              end: () => `+=${slider.current.offsetWidth * 2}`,
              scrub: 2,
              onEnterBack: () => {
                const scrollTarget =  window.outerHeight;
                  window.scrollTo({ top : scrollTarget, behavior: 'smooth' });
              },
            },
           
          });
  
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            y: '-20vh',
            rotate: 30,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            y: '-15vh',
            rotate: 50,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            y: '-10vh',
            rotate: 60,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            y: '5vh',
            rotate: 70,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: 0,
            y: '20vh',
            rotate: 70,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: '6vw',
            y: '35vh',
            rotate: 60,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: '12vw',
            y: '45vh',
            rotate: 40,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: '22vw',
            y: '40vh',
            rotate: 30,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: '28vw',
            y: '25vh',
            rotate: 20,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: '35vw',
            y: '10vh',
            rotate: 10,
          })
          rocketTimeline.to(rocketRef.current, {
            ease: "power0",
            x: '46vw',
            y: '5vh',
            rotate: 0,
          })
          gsap.to(recognition.current, {
           
            scrollTrigger: {
              trigger: recognition.current,
              start: "top top", // Pin start position with an offset of 100px
              end: () => `+=400`, // Pin end position based on the element's height
              pin: true,
              scrub: false, 
              triggerHook: 0,
            },
          });
        
        
          gsap.to(how.current, {
            ease: "none",
            scrollTrigger: {
              trigger: how.current,
              scrub: false,
              pin: ".how__left",
              end: () => `+=${how.current.offsetHeight - slider.current.offsetHeight}`,
              invalidateOnRefresh: true,
            
            },
          });
  
        
        

          // gsap.to(mentalHealth.current, {
          //   ease: "none",
          //   scrollTrigger: {
          //     trigger: mentalHealth.current,
          //     scrub: 3,
          //     pin: ".mentalHealth",
          //     end: () => `+=${mentalHealth.current.offsetHeight * 4}`,
          //     invalidateOnRefresh: true,
          //   },
          // });
          
          // const mentalTexts = gsap.utils.toArray(".mental-health__title");
  
          // const tl = gsap.timeline({
          //   scrollTrigger: {
          //     trigger: mentalHealth.current,
          //     start: "center center",
          //     end: () => `+=${mentalHealth.current.offsetHeight * 3.5}`,
          //     scrub: 1,
          //   },
          // });
          // tl.set(mentalTexts[1], { autoAlpha: 0, display: 'none' });
  
          // // animate the first element to fade out
          // tl.to(mentalTexts[0], { autoAlpha: 0, display: 'none' });
          // tl.to(
          //   mentalTexts[1],
          //   { autoAlpha: 1, display: 'block' },
          // );


          // gsap.to(whatOne.current, {
          //   ease: "none",
          //   scrollTrigger: {
          //     trigger: whatOne.current,
          //     scrub: true,
          //     pin: whatOne.current,
          //     end: () => `+=${weAreOne.current.offsetHeight}`,
          //   },
          // });
          // gsap.to(weAreOne.current, {
          //   ease: "none",
          //   scrollTrigger: {
          //     trigger: weAreOne.current,
          //     scrub: true,
          //     pin: weAreOne.current,
          //     end: () => `+=${weAreOne.current.offsetHeight}`,
          //   },
          // });

  
          // gsap.to(weAreOne.current, {
          //   // ease: "none",
          //   rotate: -18,
          //   x: 100,
          //   y: -100,
          //   scrollTrigger: {
          //     trigger: weAreOne.current,
          //     start: "top",
          //     end: () => `+=${weAreOne.current.offsetHeight * 1.5}`,
          //     pin: weAreOne.current,
          //     scrub: true,
          //     invalidateOnRefresh: true,
          //   }
          // })
  
          
          gsap.to(pricing.current, {
            ease: 'none',
            padding: "0px 40px",
            scrollTrigger: {
              trigger: pricing.current,
              start: "center",
              end: "center",
              scrub: 1,
            },
          });
    
        }, component);
        return () => ctx.revert();
  
      }


    }
    );
    const howLeftRef = useRef(null);
    const howRightRefs = useRef([]);
    const [howImg,setHowImg] = useState()
  
    useEffect(() => {
      // Initialize GSAP timeline
      const tl = gsap.timeline();
  
      // Create an Intersection Observer for each right section
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      };
  
      const observers = howRightRefs.current.map((ref, index) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Scroll reached the right section
              // Update the image in the left component
              const currentHowLeft = howLeftRef.current;
              const newImage = entry.target.dataset.image;
  
              tl.to(currentHowLeft, { opacity: 1, duration: 0.2, onComplete: () => {
                currentHowLeft.src = newImage;
                // setHowImg(newImage)
                gsap.to(currentHowLeft, { opacity: 1, duration: 0.2 });
              }});
            }
          });
        }, observerOptions);
  
        observer.observe(ref);
  
        return observer;
      });
  
      // Cleanup function
      return () => {
        observers.forEach((observer) => observer.disconnect());
      };
    }, []);
  

    return (
      <div className="homepage" ref={component}>
        <Navbar features = {how} whyone = {slider} pricing = {pricing} contactUs = {footerRef}/>
        <Home howRef={how} />
          
        <div ref={slider} className="slider">

          {(windowWidth>1024) ? 
          (
          <div ref= {rocketRef} className="rocket">
            <img src={rocket} alt="rocket" />
          </div>
          )
          : 
          (          
            <div ref= {rocketRef} className="rocket">
              <img src={rocket} alt="rocket" />
            </div>
          )
          
        }

          
          <Why />
        </div>
        
        
        <div ref={recognition} className="rec-con" style={{ scrollSnapAlign: "end" }}>
          <Recognition footerR = {footerRef}/>
        </div>
        <div ref={discoverRef} className='Discover-mg' style={{ scrollSnapAlign: "end" }}>
          <Discover/>
        </div>
        <div ref={how} className="how">
          {(windowWidth > 1024) ?
          (
            <>
                {/* <div className="how__left" ref={howLeftRef}>
                  <HowLeft photo={howImg}/>
                </div> */}
                <div className='how__left'>
            
                    <img src={ncertText} alt="" className="ncert-image" ref={howLeftRef}/>
                    <button className='StBtn'><Link to='/signup' className='link'>Start Your Free Trail</Link></button> 
                    
                    <div className='how-left__title'>Our Animated Stories are game changers.</div> 
                    <div className='how-left__main_title'>How One Works?</div> 
                </div>
                <div className="how__right">
                  <div ref={(ref) => (howRightRefs.current[0] = ref)} data-image={ncert1} className="section">
                    <HowRight heading={"Animated Stories"} number={"1"} image={howWork1} color={"#92D1F1"} text={"Transforming the way students engage with traditional textbooks by converting them into captivating and entertaining animated stories."} />
                  </div>
                  <div ref={(ref) => (howRightRefs.current[1] = ref)} data-image={ncert2} className="section">
                    <HowRight heading={"Engaging Games"} number={"2"} image={howWork2} color={"#823EA8"} text={"By combining educational content with the excitement and interactivity of video games, we are creating a new and innovative approach to learning that appeals to students of all ages."} />
                  </div>
                  <div ref={(ref) => (howRightRefs.current[2] = ref)} data-image={ncertText} className="section">
                    <HowRight heading={"Virtual Experiments"} number={"3"} image={howWork3} color={"#821431"} text={"Our virtual experiments are designed to be engaging, interactive, and safe, providing students with a truly immersive learning experience."} />
                  </div>
                  <div ref={(ref) => (howRightRefs.current[3] = ref)} data-image={ncert3} className="section">
                    <HowRight heading={"Personalized Reports"} number={"4"} image={howWork4} color={"#84A4FC"} text={"Topic-wise tracking of students' progress allows us to provide the students' teachers, school principals, and parents with deep insights into students' progress on parents' WhatsApp and various dashboards for teachers, students, and principals."} />
                  </div>
                </div>
            </>
          )
          :
          (
            <>
              <div className="how__left">
                <HowLeft photo={ncertMob}/>
              </div>
              <div className="container">
                  <div className="how__right">
                    <HowRight heading={"Animated Stories"} number={"1"} image={howWork1} color={"#92D1F1"} text={"Transforming the way students engage with traditional textbooks by converting them into captivating and entertaining animated stories."} />
                    <HowRight heading={"Engaging Games"} number={"2"} image={howWork2} color={"#823EA8"} text={"By combining educational content with the excitement and interactivity of videogames, we are creating a new and innovative approach to learning that appeals to students of all ages."} />
                  </div>
                  <div className="how__right">
                    <HowRight heading={"Virtual Experiments"} number={"3"} image={howWork3} color={"#821431"} text={"Our virtual experiments are designed to be engaging, interactive, and safe, providing students with a truly immersive learning experience."} />
                    <HowRight heading={"Personalized Reports"} number={"4"} image={howWork4} color={"#84A4FC"} text={"Topic-wise tracking of students' progress allows us to provide the students' teachers, school principals, and parents with deep insights into students' progress on parents' WhatsApp and various dashboards for teachers, students, and principals."} />
                    <button className='StBtn'><Link to='/signup' className='link'>Start Your Free Trail</Link></button> 
                  </div>
              </div>
            </>
          )
          }
         
         

        </div>
        <div ref={report} className="report">
          <Reports />
        </div>
        <div ref={mentalHealth} className="mentalHealth">
          <MentalHealth>
            <div className='mental-health__title'>
              <img src={weCareText} alt="We care about your mental health" />
              <div className="bottom-text">
                Our education startup is dedicated to not only providing students with high-quality educational resources but also prioritizing their mental health and well-being.
              </div>
            </div>

          </MentalHealth>
        </div>

        <div  ref={whatOne}  style={{ scrollSnapAlign: "end" }}>
          <WhatIsOne />
        <div style={{backgroundColor:'#EEDF05'}}>
        </div>
        </div>
        <div  ref={weAreOne}  >
          <WeAreOne />
        </div>
        <div  ref={pricing}>
          <Pricing windowWidth={windowWidth} />
        </div>
        <div ref={footerRef}>
          <Footer />
        </div>
      </div>
    );
  }
