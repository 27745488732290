import React from 'react'
import './weAreOne.scss'
import weAreOne from '../../../../assets/we-are-one.svg'
import { Link, useNavigate } from 'react-router-dom'
import arrowIcon from '../../../../assets/arrow-icon.svg'
import weAreOneDots from "../../../../assets/we-are-one-dots.svg"
import vid2 from "../../../../assets/Animations/weareoneAnimation.gif"
import Lottie from 'react-lottie';

function WeAreOne() {

    const navigate = useNavigate()
    return (


        <div className='we-are-one'>
            <div className="row">
                <div className="left-content">
                <img className='we-are-one-dots' src={weAreOneDots} alt="we-are-one-dots" />

                    <div className="title">We are One.</div>
                    <div className="sub-title">One is the world's First interactive textbook, which strives to engage students throughout a chapter through meaningful activities. At the same time, we track and share students' chapter topic-wise performance data with teachers, principals, and parents. Involving each pillar of education.</div>
                    <div className="exp-button" onClick={()=>{navigate('/signup')}}>
                        Experience Now 
                        <img src={arrowIcon} alt="Experince now" style={{ marginLeft: '8px' }}/>
                    </div>

                </div>
                <div className="right-content">
                    <img src={vid2} alt="We are One" className='img' />
                </div>
            </div>

     
        </div>


    )
}

export default WeAreOne