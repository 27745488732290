import React, { useState, useEffect } from 'react'
import './footer.scss'
import done from '../../../../assets/done.svg'
import footerLogo from '../../../../assets/footer-logo.svg'
import footerLogoSmall from '../../../../assets/footer-logo-small.svg'
import footerArrow from '../../../../assets/arrow_footer.svg'



function Footer() {

    // const handleDemeRequest = (event) => {
    //     alert("demo request")
    // }

    const [windowWidth, setWindowWidth] = useState(window.outerWidth);
    const [emailContent,setEmail] = useState("");

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.outerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sendEmail = () => {
        const mailtoLink = `mailto:Learnwithone@gmail.com?&body=${encodeURIComponent(emailContent)}`;
        window.location.href = mailtoLink;
    };

    return (
        <>

            {(windowWidth > 1024) ?
                (
                    <div>
                        <div className='footer'>
                            <div className="col-1">
                                Have Queries? Get in Touch
                                <form>
                                        <input type="text" placeholder='Your query?' onChange={(e)=>{setEmail(e.target.value)}}></input>
                                        <a className='done' href={`mailto:Learnwithone@gmail.com?&body=${encodeURIComponent(emailContent)}`}>
                                            <img src={footerArrow} alt="" />
                                        </a>
                                </form>
                                <p className='emailPara'>or email us at <a href="mailto:Learnwithone@gmail.com">team@learnwithone.co.in</a><b></b></p>
                            </div>
                            <div className="col-2">
                                Resources
                                <div className='item'>
                                    <a href="#">Testimonials</a>

                                    <a href="#">Why One?</a>

                                    <a href="#">Webinars</a>

                                    <a href="#">Our Solution</a>

                                    <a href="#">Blog</a>
                                </div>
                            </div>
                            <div className="col-2">
                                Company
                                <div className='item'>
                                    <a href="#">About us</a>

                                    <a href="#">Leaderships</a>

                                    <a href="https://docs.google.com/forms/d/1zIcXwIFmB5MOoh4LKqbB4_Ns7s9sVIJkfonA-rjsth8/edit">Careers</a>

                                    <a href="#">Partners</a>

                                    <a href='https://yourstory.com/companies/one'>Our story</a>
                                </div>
                            </div>
                            <div className="col-2">
                                Social
                                <div className='item'>
                                    <a href="https://www.linkedin.com/company/learnwithone/">LinkedIn</a>

                                    <a href="https://www.instagram.com/learnwithone/">Instagram</a>

                                    <a href="https://twitter.com/learnwithone?s=11&t=V3qUxu8KERZJa7B6JaFEBw">Twitter</a>

                                    <a href="https://www.facebook.com/learnwithone">Facebook</a>

                                    <a href="https://www.youtube.com/@ONE-ug4lg/featured">Youtube</a>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            Unparalled Learning Technologies (OPC) Private Limited
                            <div className="bottom-links">

                                <a href="mailto:Learnwithone@gmail.com" style={{zIndex:3}}>Support</a>
                                <a href="https://www.learnwithone.co.in/privacy-policy" style={{zIndex:3}}>Privacy Policy</a>
                                <a href="https://www.learnwithone.co.in/terms-of-service#ugc" style={{zIndex:3}}>Terms of Use</a>
                            </div>
                        </div>
                        <img src={footerLogo} alt="one" className="footer-logo" />
                    </div>
                ) : (
                    <div>
                        {(windowWidth >= 768) ?

                            (
                                <div>
                                    <div className='footer'>
                                        <div className="col-1">
                                            <p>Have Queries? Get in Touch</p>
                                            <form>
                                            <input type="text" placeholder='Your query?' onChange={(e)=>{setEmail(e.target.value)}}></input>
                                                <a className='done' href={`mailto:Learnwithone@gmail.com?&body=${encodeURIComponent(emailContent)}`}>
                                                    <img src={footerArrow} alt="" />
                                                </a>
                                            </form>
                                            <p className='emailPara'>or email us at <a href="mailto:Learnwithone@gmail.com">team@learnwithone.co.in</a><b></b></p>
                                        </div>
                                        <div className='col-links'>
                                            <div className="col-2">
                                                Resources
                                                <div className='item'>
                                                    <a href="#">Testimonials</a>

                                                    <a href="#">Why One?</a>

                                                    <a href="#">Webinars</a>

                                                    <a href="#">Our Solution</a>

                                                    <a href="#">Blog</a>
                                                </div>


                                            </div>
                                            <div className="col-2">
                                                Company
                                                <div className='item'>
                                                    <a href="#">About us</a>

                                                    <a href="#">Leaderships</a>

                                                    <a href="https://docs.google.com/forms/d/1zIcXwIFmB5MOoh4LKqbB4_Ns7s9sVIJkfonA-rjsth8/edit">Careers</a>

                                                    <a href="#">Partners</a>

                                                    <a href='https://yourstory.com/companies/one'>Our story</a>
                                                </div>

                                            </div>
                                            <div className="col-2">
                                                Social
                                                <div className='item'>
                                                    <a href="https://www.linkedin.com/company/learnwithone/">LinkedIn</a>

                                                    <a href="https://www.instagram.com/learnwithone/">Instagram</a>

                                                    <a href="https://twitter.com/learnwithone?s=11&t=V3qUxu8KERZJa7B6JaFEBw">Twitter</a>

                                                    <a href="https://www.facebook.com/learnwithone">Facebook</a>

                                                    <a href="https://www.youtube.com/@ONE-ug4lg/featured">Youtube</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <p>Unparalled Learning Technologies (OPC) Private Limited</p>
                                            <a href="mailto:Learnwithone@gmail.com" style={{zIndex:3}}>Support</a>
                                            <a href="https://www.learnwithone.co.in/privacy-policy" style={{zIndex:3}}>Privacy Policy</a>
                                            <a href="https://www.learnwithone.co.in/terms-of-service#ugc" style={{zIndex:3}}>Terms of Use</a>
                                        </div>
                                        <div className='logo'>
                                            <img src={footerLogo} alt="one" className="footer-logo" />
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            (

                                <>
                                    <div className='footer'>
                                        <div className="col-1">
                                            <div className='text'>

                                            Have Queries? Get in Touch
                                            </div>
                                            <form>
                                            <input type="text" placeholder='Your query?' onChange={(e)=>{setEmail(e.target.value)}}></input>
                                                <a className='done' href={`mailto:Learnwithone@gmail.com?&body=${encodeURIComponent(emailContent)}`}>
                                                    <img src={footerArrow} alt="" />
                                                </a>
                                            </form>
                                            <p className='emailPara'>or email us at <a href="mailto:Learnwithone@gmail.com">team@learnwithone.co.in</a><b></b></p>
                                        </div>
                                        <div className='col-links'>
                                            <div className="col-2">
                                                Resources
                                                <div className='item'>
                                                    <a href="#">Testimonials</a>

                                                    <a href="#">Why One?</a>

                                                    <a href="#">Webinars</a>

                                                    <a href="#">Our Solution</a>

                                                    <a href="#">Blog</a>
                                                </div>


                                            </div>
                                            <div className="col-2">
                                                Company
                                                <div className='item'>
                                                    <a href="#">About us</a>

                                                    <a href="#">Leaderships</a>

                                                    <a href="https://docs.google.com/forms/d/1zIcXwIFmB5MOoh4LKqbB4_Ns7s9sVIJkfonA-rjsth8/edit">Careers</a>

                                                    <a href="#">Partners</a>

                                                    <a href='https://yourstory.com/companies/one'>Our story</a>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='col-links'>
                                            <div className="col-2">
                                                Social
                                                <div className='item'>
                                                    <a href="https://www.linkedin.com/company/learnwithone/">LinkedIn</a>

                                                    <a href="https://www.instagram.com/learnwithone/">Instagram</a>

                                                    <a href="https://twitter.com/learnwithone?s=11&t=V3qUxu8KERZJa7B6JaFEBw">Twitter</a>

                                                    <a href="https://www.facebook.com/learnwithone">Facebook</a>

                                                    <a href="https://www.youtube.com/@ONE-ug4lg/featured">Youtube</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className='bottom-row'>
                                                Unparalled Learning Technologies (OPC) Private Limited
                                            </div>
                                            <div className="bottom-links">
                                                <a href="mailto:Learnwithone@gmail.com" style={{marginLeft:'1%',zIndex:3}}>Support</a>
                                                <a href="https://www.learnwithone.co.in/privacy-policy" style={{zIndex:3}}>Privacy Policy</a>
                                                <a href="https://www.learnwithone.co.in/terms-of-service#ugc" style={{zIndex:3}}>Terms of Use</a>
                                            </div>
                                            <div className='logo'>
                                                <img src={footerLogoSmall} alt="one" className="footer-logo" />
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default Footer