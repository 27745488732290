import React, { useEffect } from 'react'
import "./login.scss"
import logoBlack from '../../assets/one_logo2.png'
import loginDisplay from "../../assets/educatorLoginPic.svg"
import Lod from '../../assets/Rolling-1s-200px.svg'
// import email from "../../assets/email_img.png"
import googleIcon from "../../assets/GoogleLogo.png"
import { useState ,useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GoogleAuthProvider, onAuthStateChanged, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup, signOut, sendPasswordResetEmail} from 'firebase/auth'
import { auth, db, provider } from '../../services/firebase'
import { doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import { Student, studentConverter } from '../../models/UserModel'
import { Authcontext } from '../../contextProvider'
import { collection, orderBy, query, updateDoc, where } from 'firebase/firestore';

function EducatorLogin() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email,setEmail] = useState("")
  const [forgotPass,setFP] = useState(false);
  const [educator,setEducator] = useState('T');
  const userDataRef = collection(db, 'educators');
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const {currentUser} = useContext(Authcontext)
  const {loading,setLoading} = useContext(Authcontext);
  const [verification,setVerification] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const q1 = query(userDataRef,where('id','==',`${uid}`))
        const docSnap = await getDocs(q1);
        let temp = []
        try {
          docSnap.forEach((doc) => {
              temp.push(doc.data())
          })
        } catch (err) {
            console.log(err)
        }
        console.log(temp)
        if (temp[0]) {
          if(educator == 'T' && temp[0].educatorType == 'teacher'){
            navigate('/Teacher-Dashboard');
          }
          else if(educator == 'P' && temp[0].educatorType == 'Principle'){
            navigate('/Principal-Dashboard')
          }
        }
      } 
    })
  },[]);

  const handleLogin = async (event) => {
    if(!forgotPass){
      setLoading(true);
      event.preventDefault();
      const formData = new FormData(event.target);
      const data = {};
      for (let [key, value] of formData.entries()) {
        data[key] = value;
      }
      await signInWithEmailAndPassword(auth, data['email'], data['password'])
        .then((userCredential) => {
          const user = userCredential.user;
          console.log(user);
          if (user.emailVerified) {
            setLoading(false)
            if(educator == 'T'){
              navigate('/Teacher-Dashboard');
            }
            else if(educator == 'P'){
              navigate('/Principle-Dashboard');
            }
          } else {
            alert("Please verify your email before signing in.");
            console.log(user);
            setLoading(false);
            sendEmailVerification(auth.currentUser)
              .then(()=>{
                console.log('logging out')
                signOut(auth);
              })
              .catch((err)=>{
                console.log(err)
              })
  
          }
        
        })
        .catch((error) => {
          const errorMessage = error.message;
          alert(errorMessage)
          setLoading(false)
        });
    }
  }

  const handleContinueWithGoogle = () => {
    setLoading(true)
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const currentUser = result.user;
        const docRef = doc(db, "users", currentUser.uid).withConverter(studentConverter);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          const user = new Student({ id: currentUser.uid, fname: currentUser.displayName.split(" ")[0], lname: currentUser.displayName.split(" ").slice(1).join(" "), email: currentUser.email, phone: currentUser.phoneNumber, })
          await setDoc(docRef, user);
        }
        if(educator == 'T'){
          navigate('/Teacher-Dashboard');
        }
        else if(educator == 'P'){
          navigate('/Principle-Dashboard');
        }
        setLoading(false)
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        alert(errorMessage)

      });
  }
  const ResendVerification = ()=>{
    console.log('hi');
  }
  const handleForgotPass = async() =>{
    
    try {
      console.log(email)
      await sendPasswordResetEmail(auth,email)
        .then(()=>{
          alert('Password reset email sent. Check your inbox.');
        })
    } catch (error) {
      console.error(error);
      alert('Error sending reset email. Please try again.');
    }
  }


  return (
    <div className='login'>
    {
      loading && 
      <div className='loading' style={{backgroundColor:'#000000ba',position:'fixed',zIndex:'3',width:'100vw',height:'100vh'}}>
        <img src={Lod} style={{position:'absolute',width:'20%',height:'15%',left:'40%',top:'40%'}}></img>
      </div>
    }
    <div className='learnwithone'>
      <Link className='Link' to='/' style={{backgroundColor:'#66B4C6'}}>learnwithone.com ➚</Link>
      <p className='p1'>“A gift from your seniors to their juniors.”</p>
      <p className='p2'>“Change is the end result of all true learning.” <br></br>– Leo Buscaglia</p>
    </div>

      <div className='left-content'>
        <div className='row'>

          <Link to={'/'}>
            <img className='logo' src={logoBlack} alt="One-logo" />
          </Link>

        </div>
        {
          !verification && 
          <>
          {/* <div className='title'>Welcome Back</div> */}
          <div className='selector'>
            { educator == 'T' && 
                <button onClick={()=>{setEducator('T')}} style={{backgroundColor:'#66B4C6'}}>Teacher</button>
            }
            { educator != 'T' && 
                <button onClick={()=>{setEducator('T')}}>Teacher</button>
            }
            { educator != 'P' &&
                <button onClick={()=>{setEducator('P')}}>Principle</button>
            }
            { educator == 'P' &&
                <button onClick={()=>{setEducator('P')}} style={{backgroundColor:'#66B4C6'}}>Principle</button>
            }
          </div>
          <div className="center">
            <div className='dont-have-an-account'>
                Are you a student?
                <Link className='signup-text-button' to={'/login'} style={{color:'#66B4C6',fontWeight:'600'}} >
                Click Here
                </Link>
            </div>
          </div>
          {/* <div onClick={handleContinueWithGoogle} className='continue-with-google'>
            <img src={googleIcon} alt="Continue with google" />
            <div>Continue with google</div>
          </div> */}
          <div className='or'>
            or

          </div>

          <form onSubmit={handleLogin}>
            <input className='input-feild' name='email' type="email" placeholder='Enter your email' onChange={(e)=>{setEmail(e.target.value)}}/>
            {
              !forgotPass && 
              <div className='password-section'>
                <input className='input-feild' name='password' type={showPassword ? 'text' : 'password'} placeholder='Enter your password' />

              </div>
            }
            {
              !forgotPass && 
              <button className='login-button' style={{backgroundColor:'#66B4C6'}}>
                Log In
              </button>
            }
            {
              forgotPass && 
              <button className='forgot-button' style={{backgroundColor:'#66B4C6'}} onClick={()=>{handleForgotPass()}}>
                send reset mail
              </button>
            }
          </form>
          </>
        }
        {verification && 
          <div className='verification'>
            <div className='title'>Check your entered Email for a verification link</div>
            {/* <img src={email}></img> */}
            <p>An email with a verification link has been sent to you. Open the email and click on the link to verify your account.</p>
            <label>Your email:</label>
            <input type='text' value={`${currentUser.email}`} disabled></input>
            <p>Didn’t receive any email? Please check your spam folder or try to resend the email.</p>
            <button onClick={()=>{ResendVerification()}}>Resend email</button>

          </div>
        }
        <button onClick={()=>{setFP(true)}} style={{backgroundColor:'transparent',border:'none',marginLeft:'2%',marginTop:'-1%',color:'#66B4C6',textDecoration:'underline',fontWeight:'500'}}>Forgot Password?</button>
        {/* <div className="center">
          <div className='dont-have-an-account'>
            Don’t have an account?
            <Link className='signup-text-button' to={'/signup'}>
              Sign up
            </Link>
          </div>
          <button onClick={()=>{setFP(true)}} style={{backgroundColor:'transparent',border:'none',marginLeft:'2%',marginTop:'-1%',color:'orange',textDecoration:'underline',fontWeight:'500'}}>Forgot Password?</button>
        </div> */}

      </div>
      <div className='right-content'>
        <img className='login-display' src={loginDisplay} alt="Login display image" />

      </div>
    </div>
  )

}

export default EducatorLogin;