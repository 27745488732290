import React, { useEffect, useState } from 'react'
import plansBackground from '../../../../assets/waves-bg.svg'
import personalBackground from '../../../../assets/personal-bg.svg'
import tick from '../../../../assets/pricing-tick.svg'
import close from '../../../../assets/pricing-close.svg'


import './pricing.scss'
import { Link } from 'react-router-dom'

function Pricing() {
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.outerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='pricing'>
      <img className='background' src={plansBackground} alt="plans" />
      <div className="content">
        <div className="column">
          <div className="title">
            Simple Pricing for everyone
          </div>
          <div className="sub-title">
            Our plans are tailored to provide the features and functionality you need to succeed, with pricing that reflects the level of service you require.
          </div>
          <div className="card">
            <table>

              {(windowWidth < 768) ?
                <>
                  <div className="personal">
                    <img className='personal-bg' src={personalBackground} alt="" />
                    <div className="personal-content">
                      <div className="title">
                        Premium
                      </div>
                      <div className="price">
                        1999
                        <div className="rupees-symbol">₹</div>
                      </div>
                      <div className="sub-title">
                        Per Textbook
                      </div>
                    </div>
                  </div>
                  <div className='personal-table'>
                    <tbody>
                      <tr>
                        <td className='title'>Access to all chapters</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                      <tr>
                        <td className='title'>One-Page Notes</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                      <tr>
                        <td className='title'>AI backed Tests</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                      <tr>
                        <td className='title'>Advanced Reports</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                      <tr>
                        <td className='title'>User Support</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                    </tbody>
                  </div>
                  <div className="free-plan">
                    <div className="free-plan__title">
                      Free Plan
                    </div>
                    <div className="free-plan__sub-title">
                    Access to Limited features
                    </div>
                    <Link to='/signup' className='signup-button'>
                      Try for Free
                    </Link>
                  </div>
                  <div className='personal-table'>
                    <tbody>
                      <tr>
                        <td className='title'>Access to all chapters</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td className='title'>One-Page Notes</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                      <tr>
                        <td className='title'>AI backed Tests</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                      <tr>
                        <td className='title'>Advanced Reports</td>
                        <td><img src={close} alt="" className="tick" /></td>

                      </tr>
                      <tr>
                        <td className='title'>User Support</td>
                        <td><img src={tick} alt="" className="tick" /></td>
                      </tr>
                      <tr className='non-ref'>
                        *Non Refundable/Non Cancelable
                      </tr>
                    </tbody>
                  </div>
                </>

                :
                <tbody>
                  <tr>
                    <td className='title'></td>
                    <td>
                      <div className="personal">
                        <img className='personal-bg' src={personalBackground} alt="" />
                        <div className="personal-content">
                          <div className="title">
                            Premium
                          </div>
                          <div className="price">
                            1999
                            <div className="rupees-symbol">₹</div>
                          </div>
                          <div className="sub-title">
                            Per Textbook
                          </div>
                        </div>
                      </div>

                    </td>
                    <td>
                      <div className="free-plan">
                        <div className="free-plan__title">
                          Free Plan
                        </div>
                        <div className="free-plan__sub-title">
                          Access to Limited features
                        </div>
                        <Link to='/signup' className='signup-button'>
                          Try for Free
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='title'>Access to all chapters</td>
                    <td><img src={tick} alt="" className="tick" /></td>
                    <td>3 Chapters</td>
                  </tr>
                  <tr>
                    <td className='title'>One-Page Notes</td>
                    <td><img src={tick} alt="" className="tick" /></td>
                    <td><img src={tick} alt="" className="tick" /></td>
                  </tr>
                  <tr>
                    <td className='title'>AI backed Tests</td>
                    <td><img src={tick} alt="" className="tick" /></td>
                    <td><img src={tick} alt="" className="tick" /></td>
                  </tr>
                  <tr>
                    <td className='title'>Advanced Reports</td>
                    <td><img src={tick} alt="" className="tick" /></td>
                    <td><img src={close} alt="" className="tick" /></td>
                  </tr>
                  <tr>
                    <td className='title' style={{paddingBottom:'5vh'}}>User Support</td>
                    <td><img src={tick} alt="" className="tick" /></td>
                    <td><img src={tick} alt="" className="tick" /></td>
                  </tr>
                  <tr className='non-ref'>
                    *Non Refundable/Non Cancelable
                  </tr>
                </tbody>
              }
            </table>
          </div>

        </div>
      </div>
    </div>


  )
}

export default Pricing