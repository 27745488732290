import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../services/firebase";
import "./BlogList.scss";
import BlogAppBar from "./BlogAppBar";
import BlogHead from "./BlogHead";
import Footer from "../Homepage/sections/Footer/Footer";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getBlogsFromFirebase = async () => {
      const blogsCollection = collection(db, "one-blogs");
      const response = await getDocs(blogsCollection);
      const data = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setBlogs(data);
    };

    getBlogsFromFirebase();
  }, []);

  if (blogs.length === 0) {
    return <p>Loading...</p>;
  }

  const latestBlog = blogs[0];
  const oldBlogs = blogs.slice(1,4);

  return (
    <div className="blog-page">
      <BlogAppBar />
      {/* <BlogHead /> */}
      {/* <h3>
        Recent blog posts
      </h3> */}
      <div className="blogs">
        <div className="latest-blog">
          <img src={latestBlog.title_picture} alt={latestBlog.title} />
          <div className="blog-item-texts">
            <p className="blog-time">{latestBlog.date_time}</p>
            <Link to={`${latestBlog.link}`}>{latestBlog.title}</Link>
            {/* <span className="redirect-button">&#8599;</span> */}
            <div>
              {latestBlog.description}
            </div>
            {/* <div className="tags">
              <div className="tag">lorem</div>
              <div className="tag">ipsum</div>
            </div> */}
          </div>
        </div>
        <div className="old-blogs">
          {oldBlogs.map((blog) => (
            <div key={blog.id} className="blog-item">
              <img src={blog.title_picture} alt={blog.title} />
              <div className="blog-item-texts">
                <p className="blog-time">{blog.date_time}</p>
                <div><Link to={`${blog.link}`}>{blog.title}</Link> </div>
                {/* &#8599;  */}
                <div className="blog-desc">
                  {blog.description}
               </div>
                {/* <div className="tags">
                  <div className="tag">lorem</div>
                  <div className="tag">ipsum</div>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default BlogList;
