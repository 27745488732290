


import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAUG8F5Z-4I9YyDqqv90YLwR5fxk-8UAGU",
  authDomain: "one-web-e67c3.firebaseapp.com",
  projectId: "one-web-e67c3",
  storageBucket: "one-web-e67c3.appspot.com",
  messagingSenderId: "449761961140",
  appId: "1:449761961140:web:653ce5feb4917d235ee3e1",
  measurementId: "G-SYJVW2DJPF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const storage = getStorage()
export const provider = new GoogleAuthProvider();

export const db = getFirestore(app);



