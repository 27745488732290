// DynamicChart.js
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const DynamicChart = ({userData}) => {
    const [data,setData] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);
useEffect(()=>{
    let temp = []
    for(let i=0;i<userData.above60.length;i++){
        // console.log(userData.above60[i])
        temp = [...temp,{name:`CH${i}`,Above_60:userData.above60[i],Below_60:userData.below60[i]}]
        // temp = [...temp,{name:i+1,Above_60:i+1,Below_60:i+2}]
    }
    setData(temp);
    const handleKeyDown = (event) => {
        // Check if the Ctrl key (or Command key on Mac) is pressed
        if ((event.ctrlKey || event.metaKey) && event.key === "+") {
          event.preventDefault(); // Prevent the default browser zoom behavior
        }
      };
  
      // Add the event listener when the component mounts
      document.addEventListener("keydown", handleKeyDown);
  
      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };

},[])
  return (
    <div>
    {
        windowWidth > 1024 && 
        <div style={{touchAction: 'none'}}>
        <LineChart
          width={window.innerWidth*0.2}
          height={window.innerHeight*0.28}
            data={data}
            style={{touchAction: 'none'}}
            options={{

            }}
            margin={{ top: 5, right: 15, left: 5, bottom: 10 }}
        >
            <XAxis dataKey="name" angle={-90} textAnchor="end" interval={0}   tick={{
                fontSize: 12, // Adjust the font size as needed
            }}/>
            <YAxis tick={{
                fontSize: 12, // Adjust the font size as needed
            }} domain={[0, 'dataMax']}/>
            <Tooltip />
            {/* <Legend  wrapperStyle={{ marginTop: '50' }} style={{marginTop:'20px'}}/> */}
            <Line type="monotone" dataKey="Above_60" stroke="#F4E61B" strokeWidth={7}/>
            <Line type="monotone" dataKey="Below_60" stroke="#C93000" strokeWidth={5}/>
        </LineChart>
        </div>
    }
    {
        windowWidth > 768 && windowWidth <= 1024 && 
        <LineChart
            width={240}
            height={250}
            data={data}
            options={{
                responsive: true, // Make the chart responsive
                maintainAspectRatio: false, // Don't maintain the aspect ratio (so it can be responsive)
                scales: {
                // Your scale configurations here
                },
            }}
            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
        >
            <XAxis dataKey="name" />
            <YAxis domain={[0, 'dataMax']}/>
            <Tooltip />
            {/* <Legend /> */}
            <Line type="monotone" dataKey="Above_60" stroke="#F4E61B" strokeWidth={7}/>
            <Line type="monotone" dataKey="Below_60" stroke="#C93000" strokeWidth={5}/>
        </LineChart>
    }
    {
        windowWidth <= 768 && windowWidth >= 410 &&
        <LineChart
            width={320}
            height={250}
            data={data}
            options={{
                responsive: true, // Make the chart responsive
                maintainAspectRatio: false, // Don't maintain the aspect ratio (so it can be responsive)
                scales: {
                // Your scale configurations here
                },
            }}
            margin={{ top: 10, right: 10, left: -20, bottom: 25 }}
        >
            <XAxis dataKey="name" angle={-90} textAnchor="end" interval={0}   tick={{
                fontSize: 10, // Adjust the font size as needed
            }}/>
            <YAxis tick={{
                fontSize: 10, // Adjust the font size as needed
            }}  domain={[0, 'dataMax']}/>
            <Tooltip />
            {/* <Legend /> */}
            <Line type="monotone" dataKey="Above_60" stroke="#F4E61B" strokeWidth={7}/>
            <Line type="monotone" dataKey="Below_60" stroke="#C93000" strokeWidth={5}/>
        </LineChart>
    }
    {
        windowWidth < 410 && 
        <LineChart
            width={280}
            height={200}
            data={data}
            options={{
                responsive: true, // Make the chart responsive
                maintainAspectRatio: false, // Don't maintain the aspect ratio (so it can be responsive)
                scales: {
                // Your scale configurations here
                },
            }}
            margin={{ top: 10, right: 10, left: -20, bottom: 25 }}
        >
            <XAxis dataKey="name" angle={-90} textAnchor="end" interval={0}   tick={{
                fontSize: 10, // Adjust the font size as needed
            }}/>
            <YAxis tick={{
                fontSize: 10, // Adjust the font size as needed
            }} domain={[0, 'dataMax']}/>
            <Tooltip />
            {/* <Legend /> */}
            <Line type="monotone" dataKey="Above_60" stroke="#F4E61B" strokeWidth={7}/>
            <Line type="monotone" dataKey="Below_60" stroke="#C93000" strokeWidth={5}/>
        </LineChart>
    }

    </div>
  );
};

export default DynamicChart;
