import React, { Children, useLayoutEffect, useRef } from 'react'
import mentalHealthImg from '../../../../assets/mental-health.jpg'
import './mentalHealth.scss'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Link, useNavigate } from 'react-router-dom'

function MentalHealth({ children }) {
  const navigate = useNavigate()
  return (
    <>
      <div className='mental-health'>
        <div className='title'>
         {children}
        </div>
        

        <div className="right-section">
          <img src={mentalHealthImg} alt="Mental Health Image" />
        </div>
          <button onClick={()=>{navigate('/signup')}} className='btn'>Start Now</button>
      </div>
      
    </>
  )
}

export default MentalHealth
