import { Authcontext } from '../../../contextProvider'
import SideBar from '../SideBar/SideBar'
import './T&PDashboard.scss'


import objects from '../../../assets/OBJECTS.svg'
import btn from '../../../assets/profileResumeBtn.png'
import ChartIcon from '../../../assets/chartIcon.svg'
import rocketProfile from '../../../assets/rocketProfile.png'
import MHicon from '../../../assets/mentalHealthIcon.png'
import analytics from '../../../assets/ion_analytics-outline.svg'
import NavBarMob from '../SideBar/NavBarMob'
import DynamicChart from './DynamicChart'
import defaultPic from '../../../assets/defaultPic.svg'
import arrow2 from '../../../assets/Vector2.svg'
import arrow1 from '../../../assets/Vector(2).svg'
import arrow3 from '../../../assets/Vector(1).svg'
import star from '../../../assets/_.svg'
import one from '../../../assets/1.png'
import two from '../../../assets/2.png'
import three from '../../../assets/3.png'
import four from '../../../assets/4.png'
import five from '../../../assets/5.png'
import six from '../../../assets/6.png'
import seven from '../../../assets/7.png'
import eight from '../../../assets/8.png'
import nine from '../../../assets/9.png'
import ten from '../../../assets/10.png'
import eleven from '../../../assets/11.png'
import twelve from '../../../assets/12.png'
import React, { useContext, useEffect, useState } from 'react'
import { collection, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { getDocs, doc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../services/firebase'
import { onAuthStateChanged ,sendEmailVerification,signOut } from 'firebase/auth'


function PrincipleDashboard() {
    const userDataRef = collection(db, 'educators');
    const userDataRef1 = collection(db, 'chapters');
    const userDataRef2 = collection(db, 'user_data');
    const userDataRef3 = collection(db,'users')
    const [userData,setUD] = useState();
    const [classes,setClasses] = useState([]);
    const {currentUser} = useContext(Authcontext)
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);
    const [currentClass,setCC] = useState('');
    const [currentSection,setCS] = useState('');
    const [currentSelectedSection,setCSS] = useState('')
    const [currentSelectedClass,setCSC] = useState('')
    const [chapters,setChapters] = useState([]);
    const [currentChap,setCChap] = useState('');
    const [currentChapTopics,setCCT] = useState([]);
    const [students,setStudents] = useState([]);
    const [graph,setGraph] = useState([]);
    const [lessThan,setLT] = useState([]);
    const [moreThan,setMT] = useState([]);
    const [studentData,setSD] = useState([]);
    const [allStudentdata,setASD] = useState([])

    const [selector,setSelector] = useState('C')
    useEffect(()=>{
      const fetchUserInfo =async()=>{
        const q1 = query(userDataRef,where('id','==',`${currentUser.uid}`))
        let temp1 = []
        const querySnapShot1 = await getDocs(q1)
        try {
            querySnapShot1.forEach((doc) => {
                temp1.push(doc.data())
            })
        } catch (err) {
            console.log(err)
        }

        setUD(temp1[0])
        if(temp1[0]){
          setClasses(temp1[0].classes)
        }
      }
      fetchUserInfo()

      const fetchChapInfo =async()=>{
        const q1 = query(userDataRef1,orderBy('chap_id'))
        let temp1 = []
        const querySnapShot1 = await getDocs(q1)
        try {
            querySnapShot1.forEach((doc) => {
                temp1.push(doc.data())
            })
        } catch (err) {
            console.log(err)
        }
        temp1.sort((a, b) => {
          const numberA = parseInt(a.chap_id.match(/\d+/)[0], 10);
          const numberB = parseInt(b.chap_id.match(/\d+/)[0], 10);
    
          return numberA - numberB;
        });
        setChapters(temp1)
      }
      fetchChapInfo();
    },[])

    useEffect(()=>{
      if(userData && userData.school){
        const fetchStudentInfo =async()=>{
          const q1 = query(userDataRef3,where('schoolName','==',`${userData.school}`))
          let temp1 = []
          const querySnapShot1 = await getDocs(q1)
          try {
              querySnapShot1.forEach((doc) => {
                  temp1.push(doc.data())
              })
          } catch (err) {
              console.log(err)
          }
          setSD(temp1)
        }
        fetchStudentInfo();
      }
    },[userData])

    useEffect(()=>{
      let data = studentData;
      if(studentData){
        const fetchAllStudentInfo = async (i)=>{
          const q1 = query(userDataRef2,where('id','==',`${studentData[i].id}`))
          let temp1 = []
          const querySnapShot1 = await getDocs(q1)
          try {
              querySnapShot1.forEach((doc) => {
                  temp1.push(doc.data())
              })
          } catch (err) {
              console.log(err)
          }
          studentData[i] = {...studentData[i],userData: temp1[0]}
        }
        for(let i=0;i<studentData.length;i++){
          fetchAllStudentInfo(i)
        }
        console.log(data)
        setASD(data)
      }
    },[studentData])

    const viewDetails = async () =>{
      if(selector == 'C' && allStudentdata.length > 0){
        let Total = Array.from({ length: chapters.length }, () => 0);
        let noOfStudents = Array.from({ length: chapters.length }, () => 0);
        for(let i=0;i<allStudentdata.length;i++){
          let ec = allStudentdata[i].userData.enrolled_Chapters
          for(let j=0;j<ec.length;j++){
            let results = ec[j].checkpointResults
            let score = 0;
            for(let k=0;k<results.length;k++){
              score = score + results[k].score;
            }
            if(results.length != 0){
              score = score / results.length;
            }
            const chapNo = ec[j].chap_id.match(/\d+/);
            noOfStudents[chapNo]++;
            Total[chapNo] = Total[chapNo] + score;
          }
        }
        for(let j=0;j<Total.length;j++){
          if(noOfStudents[j] != 0){
            Total[j] = Total[j]/noOfStudents[j]
          }
        }
        console.log(Total)
        setGraph(Total)
      }
      else if(selector == 'S' && allStudentdata.length > 0){
        let Total = Array.from({ length: chapters.length }, () => 0);
        let noOfStudents = Array.from({ length: chapters.length }, () => 0);
        let refNeeded = `${userData.school.substring(0,3)}${currentClass}${currentSelectedSection.substring(0,3)}`
        console.log(refNeeded)
        if(currentSection != ''){
          for(let i=0;i<allStudentdata.length;i++){
            let ec = allStudentdata[i].userData.enrolled_Chapters
            let curRef = allStudentdata[i].userData.referalID.substring(0,5)
            console.log(curRef)
            if(curRef == refNeeded){
              for(let j=0;j<ec.length;j++){
                let results = ec[j].checkpointResults
                let score = 0;
                for(let k=0;k<results.length;k++){
                  score = score + results[k].score;
                }
                if(results.length != 0){
                  score = score / results.length;
                }
                const chapNo = ec[j].chap_id.match(/\d+/);
                noOfStudents[chapNo]++;
                Total[chapNo] = Total[chapNo] + score;
              }
            }
          }
          for(let j=0;j<Total.length;j++){
            if(noOfStudents[j] != 0){
              Total[j] = Total[j]/noOfStudents[j]
            }
          }
          console.log(Total)
          setGraph(Total)
        }
      }
      else if(selector == 'T' && allStudentdata.length > 0 && currentChap){
        let n = 0;
        let content = currentChap.content
        let allTopics = [];
        for(let i=0;i<content.length;i++){
          if(content[i].type == 'checkpoint'){
            console.log(content[i])
            n++;
            allTopics = [...allTopics,content[i].checkPointTopic]
          }
        }
        console.log(n)
        let Total = Array.from({ length: n}, () => 0);
        let noOfStudents = Array.from({ length: n }, () => 0);
        let refNeeded = `${userData.school.substring(0,3)}${currentClass}${currentSelectedSection.substring(0,3)}`
        if(currentSection != ''){
          for(let i=0;i<allStudentdata.length;i++){
            let ec = allStudentdata[i].userData.enrolled_Chapters
            let curRef = allStudentdata[i].userData.referalID.substring(0,5)
            if(curRef == refNeeded){
              console.log(ec)
              for(let j=0;j<ec.length;j++){
                if(ec[j].chap_id == currentChap.chap_id){
                  let results = ec[j].checkpointResults
                  console.log(results)
                  for(let k=0;k<results.length;k++){
                    Total[k] = Total[k] + results[k].score;
                    noOfStudents[k]++; 
                  }
                  console.log(Total)
                  break;
                }
              }
            }
          }
          for(let k=0;k<n;k++){
            if(noOfStudents[k] != 0){
              Total[k] = Total[k]/noOfStudents[k]
            }
          }
          let l = [];
          let m = [];
          for(let j=0;j<Total.length;j++){
            if(noOfStudents[j] != 0){
              Total[j] = Total[j]/noOfStudents[j]
              if(Total[j] > 50){
                m = [...m, {tNo:m.length + 1,topicName:`${allTopics[j]}`}]
              }
              else{
                l = [...l,{tNo:l.length + 1,topicName:`${allTopics[j]}`}]
              }
            }
          }
          setGraph(Total)
          setLT(l);
          setMT(m);
        }
      }
    }

    useEffect(()=>{
      if(currentChap != ''){
        const chapNo = currentChap.match(/\d+/);
        let integerValue = 0; 
        if (chapNo) {
            integerValue = parseInt(chapNo[0], 10);
            console.log(integerValue);
        } else {
            console.log("No numeric part found in the string.");
        }
        let noOfTests = 0;
        console.log(chapters[integerValue])
        if(students.length > 0){
          let topics = [];
          for(let i=0;i<chapters[integerValue].content.length;i++){
            if(chapters[integerValue].content[i].checkpointId){
              topics = [...topics,{tNo:`T${chapters[integerValue].content[i].checkpointId}`,topicName:chapters[integerValue].content[i].checkPointTopic}]
              noOfTests++;
            }
            setCCT(topics)
          }
          console.log(noOfTests)

          const sumOf = new Array(noOfTests).fill(0);
          const totalStudents = new Array(noOfTests).fill(0);
          for(let i=0;i<students.length;i++){
            let stChaps = [...students[i].enrolled_Chapters]
            console.log(stChaps)
            for(let j=0;j<stChaps.length;j++){
              if(stChaps[j].chap_id == currentChap){
                for(let k=0;k<stChaps[j].checkpointResults.length;k++){
                  sumOf[k] = sumOf[k] + stChaps[j].checkpointResults[k].score
                  totalStudents[k] = totalStudents[k] + 1;
                }
                break;
              }
            }
          }

          console.log(sumOf)
          console.log(totalStudents)

          for(let i=0;i<sumOf.length;i++){
            if(totalStudents[i] != 0){
              sumOf[i] = sumOf[i]/totalStudents[i];
            }
          }

          console.log(sumOf);
          setGraph(sumOf);
        }
      }
    },[students])

    useEffect(()=>{
      let l = [];
      let m = [];
      if(graph.length > 0 && selector != 'T'){
        for(let i=0;i<graph.length;i++){
          if(graph[i] >= 50){
            m = [...m,{tNo:m.length + 1,topicName:`${chapters[i].chapter_name}`}]
          }
          else{
            l = [...l,{tNo:l.length + 1 ,topicName:`${chapters[i].chapter_name}`}]
          }
        }

        setLT(l);
        setMT(m);
      }
    },[graph])

    useEffect(()=>{
      console.log(currentChap)
    },[currentChap])

    const setClassSections = (classItem) =>{
      setCS(classItem.sections)
      setCSC(classItem)
    }

    const setCurrSections = (classItem)=>{
      setClassSections(classItem)
      setCS(classItem.sections)
      setCC(classItem.class)
    }

  return (
    <div className='profile'>
          {
            windowWidth >= 768 &&
            <SideBar p = {true}/>
          }
          {
            windowWidth < 768 && 
            <NavBarMob p = {true}/>
          }
      {
        userData && userData.name &&
        <div className='profileLeft'>
          <div className='userGreetings'>
            <div className='text'>
              <p className='P1'>Welcome {userData.name}</p>
              <p className='P2'>Dashboard</p>
            </div>
            <img src={defaultPic} alt='Default profile icon' />
          </div>
          <div className='cont1'>
            <div className='classSelect'>
              <p className='heading'>You School Performance</p>
              <div className='slider' >
                  { selector == 'C' && 
                      <button onClick={()=>{setSelector('C')}} style={{backgroundColor:'#F4E61B',color:'black'}}>Class</button>
                  }
                  { selector != 'C' && 
                      <button onClick={()=>{setSelector('C')}} >Class</button>
                  }
                  { selector != 'S' &&
                      <button onClick={()=>{setSelector('S')}}>Section</button>
                  }
                  { selector == 'S' &&
                      <button onClick={()=>{setSelector('S')}} style={{backgroundColor:'#F4E61B',color:'black'}}>Section</button>
                  }
                  { selector != 'T' &&
                      <button onClick={()=>{setSelector('T')}} >Topic</button>

                  }
                  { selector == 'T' &&
                      <button onClick={()=>{setSelector('T')}} style={{backgroundColor:'#F4E61B',color:'black'}}>Topic</button>
                  }
              </div>
              <p className='heading2'>Select Grade</p>
              <div className='grades'>
              {
                classes.map((classItem) => {
                  if(currentSelectedClass != classItem){
                    return(
                      <div className='class' onClick={()=>{setCurrSections(classItem)}} style={{backgroundColor:'#2E2E2E',color:'white'}}>Grade {classItem.class}</div>
                    )
                  }
                  else{
                    return(
                      <div className='class' style={{backgroundColor:'#F3E61B',color:'black'}} onClick={()=>{setCS(classItem.sections)}}>Grade {classItem.class}</div>
                    )
                  }
                })
              }
              </div>
            </div>
            {
              (selector == 'S' || selector == 'T') && 
              <div className='sections'>
              <p className='heading2'>Select Section</p>
              <div className='sectionSelect'>
                { 
                  (selector != 'S' || selector != 'T') && currentSection == '' && 
                  <p className='alert'>Please Select a Section</p>
                }
                {
                  (selector != 'S' || selector != 'T') && currentSection != '' && 
                  currentSection.map((section)=>{
                    if(section == currentSelectedSection){
                      return (
                        <div className='section' onClick={()=>{setCSS(section)}} style={{backgroundColor:'#F3E61B',color:'black'}}>{section}</div>
                      )
                    }
                    else{
                      return(
                        <div className='section' onClick={()=>{setCSS(section)}} style={{backgroundColor:'#2E2E2E',color:'white'}}>{section}</div>
                      )
                    }
                  })
                }
                
              </div>
            </div>
            }
            {
              selector == 'T' && 
              <div className='chapterSelect'>
              <div className='selectDiv'>
                <p className='heading2'>Chapters</p>
                <select onChange={(e) => setCChap(chapters[e.target.selectedIndex])}>
                {chapters.map((chapter, i) => (
                  <option className='chapter' key={i} value={chapter}>
                    Chapter {i}
                  </option>
                ))}
              </select>
              </div>
            </div>
            }
            <button className='viewBtnP' onClick={()=>{viewDetails()}}>View</button>
          </div>
        </div>
      }

      <div className='profileRight'>
        <div className='performanceGraph'>
          <p className='heading'><img src={ChartIcon}></img> Performance Per Chapter</p>
          <div className='charts'>
            <p className='y-axis'><p>0</p> <p>20</p> <p>40</p> <p>60</p> <p>80</p> <p>100</p></p>
            {
              graph.map((val,i)=>{
                if(selector != 'T'){
                  if(val >= 50){
                      return(
                      <div className='barComponent'>
                        <div className='barOuter'>
                          <div className='barInner' style={{height:`${val}%`}}></div>
                        </div>
                        <p>CH{i+1}</p>
                      </div>
                    )
                  }
                  else{
                    return(
                      <div className='barComponent'>
                        <div className='barOuter'>
                          <div className='barInner' style={{height:`${val}%`,backgroundColor:'red'}}></div>
                        </div>
                        <p>CH{i+1}</p>
                      </div>
                    )
                  }
                }
                else{
                  if(val >= 50){
                      return(
                      <div className='barComponent'>
                        <div className='barOuter'>
                          <div className='barInner' style={{height:`${val}%`}}></div>
                        </div>
                        <p>T{i+1}</p>
                      </div>
                    )
                  }
                  else{
                    return(
                      <div className='barComponent'>
                        <div className='barOuter'>
                          <div className='barInner' style={{height:`${val}%`,backgroundColor:'red'}}></div>
                        </div>
                        <p>T{i+1}</p>
                      </div>
                    )
                  }
                }
              })
            }

          </div>
        </div>


        <div className='rightBottomSection'>

        <div className='mentalHealth'>
        <p className='heading'>Topics Names</p>
        <div className='topicsContent'>
          {
            moreThan.map((i)=>(
              <div className='topic'>
                <p className='tNo'>{i.tNo}</p>
                <p className='topicName'>{i.topicName}</p>
              </div>
            ))
          }
        </div>
        </div>
          <div className='analytics'>
          <p className='heading'>Topics Below 50%</p>
          <div className='topicsContent'>
            {
              lessThan.map((i)=>(
                <div className='topic'>
                  <p className='tNo'>{i.tNo}</p>
                  <p className='topicName'>{i.topicName}</p>
                </div>
              ))
            }
          </div>

          </div> 
        </div> 

      </div>
    </div>
  );
}

export default PrincipleDashboard;
