import './progressBar.scss'
import newspaperIcon from '../../../assets/library-img-1.svg'
import newspaperIcon2 from '../../../assets/library-img-2.svg'
import { useContext, useEffect, useState } from 'react'
import { collection, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getDocs, doc } from "firebase/firestore";
import { Authcontext } from '../../../contextProvider';
import { db } from '../../../services/firebase';
import Quiz from '../quiz/quiz';
import { useNavigate } from 'react-router-dom';

function ProgressBar(props){

    const [percentage,setPer] = useState(0)
    const {currentChapter} = useContext(Authcontext)
    const {currentUser} = useContext(Authcontext)
    const [testVis,setTestVis] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);
    const [notesVis,setnotesVis] = useState(false)

    useEffect(()=>{
        if(props.userData.length!=0){
            let temp = props.userData
            for(let i=0;i<temp.length;i++){
                if(temp[i].chap_id == `${currentChapter.chap_id}`){
                    setPer(temp[i].completion)
                    // setProgress(temp[i].completion)
                }
            }
        }
    },[props.userData])
    useEffect(()=>{
        updateProgress()
    },[props.percentage])

    const updateProgress = async()=>{
        if((props.percentage/props.total)*100>percentage){
            // setProgress((props.percentage/props.total)*100)
            setPer((props.percentage/props.total)*100)
            let temp = []
                if(props.userData.length>0){
                    temp = props.userData;
                    for(let i=0;i<temp.length;i++){
                        if(temp[i].chap_id == `${currentChapter.chap_id}`){
                            temp[i].completion = props.percentage
                        }
                    }
                }
                if(temp.length > 0){
                    await updateDoc(doc(db, "user_data", currentUser.uid), {
                        "enrolled_Chapters":temp,
                    })
                }
            }
        
    }
    // const setProgress = (percentage) => {
    //     const progressBar = document.querySelector('.PBinner');
    //     progressBar.style.width = `${percentage}%`;
    // }
    return (
        <>
            {
                windowWidth >= 768 && 
                <div className='progressTab'>
                <div className='container-1'>
                    <h5>Your progress</h5>
                    <div className='progressPopUp'> <p style={{marginLeft:`${percentage}%`}}>{parseInt(percentage)}%</p> <p style={{marginLeft:`${percentage}%`}}><b>|</b></p></div>
                    {/* <div className='progressPopUp'>75%</div> */}
                    <div className='progressBar'>
                        <div className='PBinner' style={{width:`${percentage}%`}}></div>
                    </div>
                </div>
                <div className='container-2'>
                {
                          !testVis &&
                          <>
                            <h5>Unfinished Tests</h5>
                            <img src={newspaperIcon}></img>
                            <p>Finishing your test shows your superpower of determination and courage!</p>
                              {
                                currentChapter && 
                                <button className='Lib-btn' onClick={()=>{setTestVis(true)}}>Tests <b>→</b></button>
                              }
                          </>
                        }
                        {
                          testVis &&
                          <div className='viewInfo'>
                            <button className='back' onClick={()=>{setTestVis(false)}}>Back</button>
    
                            {/* {
                              currentChapter.tests.map((test)=>(
                                <div className='component'>
                                  <p>{test.test_name}</p>
                                  <button onClick={()=>{HandleStartTest(test)}}>Start</button>
                                </div>
                              ))
                            }    */}
                            {
                                props.chapters.map((chapter)=>(
                                      <div className='component'>
                                        <p>{chapter.chapter_name}</p>
                                        <a href={`${chapter.testLink}`}>Start</a>
                                      </div>
                                ))
                              }
                            
                          </div>
                        }
                </div>
                <div className='container-2' style={{border:'none'}}>
                {
                          !notesVis && 
                          <>
                            <h5>Notes</h5>
                            <img src={newspaperIcon2}></img>
                            <p>Comprehensive notes for all chapters to revise, learn and enjoy! </p>
                            {
                              currentChapter && 
                              <button className='Lib-btn' onClick={()=>setnotesVis(true)}>Download <b>→</b></button>
                            }
                          </>
                        }
                        {
                          notesVis && 
                          <div className='viewInfo'>
                            <button className='back' onClick={()=>{setnotesVis(false)}}>Back</button>
                            {
                                props.chapters.map((chapter)=>(
                                      <div className='component'>
                                        <p>{chapter.chapter_name}</p>
                                        <a href={`${chapter.notesLink}`}>Download</a>
                                      </div>
                                ))
                              }
                          </div>
                          
                        }
                </div>
            </div>
            }
            {
                windowWidth < 768 &&
                <div className='progressTabMob' style={{visibility:`${props.view}`}}>
                    <div className='container-1'>
                          <h5>Your progress</h5>
                          <p className='per'><p style={{marginLeft:`${percentage}%`}}> {parseInt(percentage)}% <b>|</b></p></p>
                          {/* <div className='progressPopUp'>75%</div> */}
                          <div className='progressBar'>
                          <div className='PBinner' style={{width:`${percentage}%`}}></div>
                    </div>
                              
                    </div>
                    <div className='container-2'>
                        {
                            !testVis &&
                            <>
                              <h5>Unfinished Tests</h5>
                              <img src={newspaperIcon}></img>
                              <p className='desctext'>Finishing your test shows your superpower of determination and courage!</p>
                              {
                                currentChapter && 
                                <button className='Lib-btn' onClick={()=>{setTestVis(true)}}>Tests <b>→</b></button>
                              }
                            </>
                        }
                        {
                            testVis &&
                            <div className='viewInfo'>
                              <button className='back' onClick={()=>{setTestVis(false)}}>Back</button>
                              {/* {
                                tests.map((test)=>(
                                  <div className='component'>
                                    <p>{test.testName}</p>
                                    <button>Start</button>
                                  </div>
                                ))
                              }    */}
                              {
                                props.chapters.map((chapter)=>(
                                      <div className='component'>
                                        <p>{chapter.chapter_name}</p>
                                        <a href={`${chapter.testLink}`}>Start</a>
                                      </div>
                                ))
                              }
                            </div>
    
                              }
                          </div>
                          <div className='container-2' style={{border:'none'}}>
                          {
                                !notesVis && 
                                <>
                                  <h5>Notes</h5>
                                  <img src={newspaperIcon2} style={{top:'66%',height:'16%',left:'-4%'}}></img>
                                  <p className='desctext'>Comprehensive notes for all chapters to revise, learn and enjoy! </p>
                                  {
                                    currentChapter && 
                                    <button className='Lib-btn' onClick={()=>setnotesVis(true)}>Download <b>→</b></button>
                                  }
                                </>
                              }
                              {
                                notesVis && 
                                <div className='viewInfo'>
                                  <button className='back' onClick={()=>{setnotesVis(false)}}>Back</button>
                                  {
                                    props.chapters.map((chapter)=>(
                                          <div className='component'>
                                            <p>{chapter.chapter_name}</p>
                                            <a href={`${chapter.notesLink}`}>Download</a>
                                          </div>
                                    ))
                                  }
          
                                </div>
                                
                              }
                          </div>
                    </div>
                }
         </>
    )
}
export default ProgressBar