import React from 'react'
import "./whatIsOne.scss"
import { Link, useNavigate } from 'react-router-dom'
import gifts from '../../../../assets/gifts.svg'
import unWrap from '../../../../assets/unwrap-gifts.svg'
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton'

function WhatIsOne() {
    const navigate = useNavigate()
    const HandleClick=()=>{
        console.log('hi')
        navigate('/signup')
    }

    return (
        <div className='whatIsOne'>

            <div className='row'>
                <div className='column'>
                    <div className='title'>What Is One?</div>
                    <p className='subtitle'>It’s a Gift from Your Seniors to Our Juniors</p>
                    <img className='image' src={unWrap} onClick={()=>{HandleClick()}} />
                </div>
                <div className="right-column">
                    <img src={gifts} className="gifts" alt="Free trial" />
                </div>

            </div>



        </div>
    )
}

export default WhatIsOne
