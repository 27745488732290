import './NotFound.scss';
import logo from '../../assets/404_error_logo.png';
import { Link } from 'react-router-dom';

function NotFound(){
    return(
        <div className='errorPage'> 
            <img src={logo}></img>
            <div className='content'>
                <p className='heading'>The page you are looking for is out of syllabus</p>
                <Link to='/' className='link_main'>Back to Home →</Link>
                <p>Here are some of the useful links instead</p>
                <div className='other_links'>
                    <Link to='/' className='link'><b>Home</b></Link>
                    <Link to='/signup' className='link'><b>SignUp</b></Link>
                    <Link to='/login' className='link'><b>Login</b></Link>
                </div>

            </div>
        </div>
    )
}

export default NotFound;