import './discover.scss'
import paperplane from '../../../../assets/discover-paperplane.png'
import btns from '../../../../assets/discover-btns.png'

function Discover(){
    return(
        <div className="discover_inner">
            <div className='content'>
                <div className='main'>
                    <img src={paperplane}></img>
                    <div className='text'>
                        <h1>How is One delivering on So Much?</h1>
                        <p style={{marginBottom: '8%'}}>At One, we're shaking things up! We are turning boring textbooks <b>by combining four of your favorite things!  </b></p>
                        <p className='p2'>“Our textbooks follow <b>The Philosophy of Discover, Emphasize, and Verify.</b> So a <b>student discovers ideas through games & virtual experiments</b>, then the concepts are <b>emphasized through animations</b>, and lastly, we ensure students have grasped concepts well with the help checkpoints”</p>
                    </div>
                </div>
                <img src={btns} className='bts'></img>
            </div>
        </div>
    )
}

export default Discover