import React, { useEffect } from "react";
import "./login.scss";
import logoBlack from "../../assets/one_logo2.png";
import loginDisplay from "../../assets/login-display-full.jpg";
import Lod from "../../assets/Rolling-1s-200px.svg";
// import email from "../../assets/email_img.png"
import googleIcon from "../../assets/GoogleLogo.png";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, db, provider } from "../../services/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Student, studentConverter } from "../../models/UserModel";
import { Authcontext } from "../../contextProvider";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [forgotPass, setFP] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { currentUser } = useContext(Authcontext);
  const { loading, setLoading } = useContext(Authcontext);
  const [verification, setVerification] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && auth.currentUser.emailVerified) {
        const uid = user.uid;
        const docRef = doc(db, "users", uid).withConverter(studentConverter);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          var userData = docSnap.data();
          if (userData.state !== "NA") {
            navigate("/library");
          } else {
            navigate("/accountsetup");
          }
        }
      }
    });
  }, []);

  const handleLogin = async (event) => {
    if (!forgotPass) {
      setLoading(true);
      event.preventDefault();
      const formData = new FormData(event.target);
      const data = {};
      for (let [key, value] of formData.entries()) {
        data[key] = value;
      }
      await signInWithEmailAndPassword(auth, data["email"], data["password"])
        .then(async (userCredential) => {
          const user = userCredential.user;
          if (user) {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists() && docSnap.data().goal === "") {
              navigate("/accountsetup");
            } else if (docSnap.exists()) {
              navigate("/library");
            }

            if (user.emailVerified) {
              setLoading(false);
            } else {
              alert("Please verify your email before signing in.");
              setLoading(false);
              sendEmailVerification(user)
                .then(() => {
                  console.log("logging out");
                  signOut(auth);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            console.log("User is not signed in.");
            setLoading(false);
          }
        })
        .catch((error) => {
          const errorMessage = error.message;
          setLoading(false);
          if (!currentUser) {
            alert(errorMessage);
          }
        });
    }
  };

  const handleContinueWithGoogle = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const currentUser = result.user;
        const docRef = doc(db, "users", currentUser.uid).withConverter(
          studentConverter
        );
        const docSnap = await getDoc(docRef);

        const userDataRef = doc(db, "user_data", currentUser.uid);
        const userDataSnap = await getDoc(userDataRef);

        if (!docSnap.exists()) {
          const user = new Student({
            id: currentUser.uid,
            fname: currentUser.displayName.split(" ")[0],
            lname: currentUser.displayName.split(" ").slice(1).join(" "),
            email: currentUser.email,
            phone: currentUser.phoneNumber,
          });
          await setDoc(docRef, user);
        }
        if (!userDataSnap.exists()) {
          try {
            await setDoc(doc(db, "user_data", currentUser.uid), {
              current_chap: [],
              enrolled_Chapters: [],
              id: currentUser.uid,
              completion: 0,
              testResults: [],
            }).then(() => {
              setLoading(false);
            });
          } catch (err) {
            console.log(err);
          }
        }
        if (docSnap.data().goal == "") {
          navigate("/accountsetup");
        } else {
          navigate("/library");
        }
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        alert(errorMessage);
      });
  };
  const ResendVerification = () => {
    console.log("hi");
  };
  const handleForgotPass = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent. Check your inbox.");
    } catch (error) {
      alert("Error sending reset email. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="login">
      {loading && (
        <div
          className="loading"
          style={{
            backgroundColor: "#000000ba",
            position: "fixed",
            zIndex: "3",
            width: "100vw",
            height: "100vh",
          }}
        >
          <img
            src={Lod}
            style={{
              position: "absolute",
              width: "20%",
              height: "15%",
              left: "40%",
              top: "40%",
            }}
          ></img>
        </div>
      )}
      <div className="learnwithone">
        <Link className="Link" to="/">
          learnwithone.com ➚
        </Link>
        <p className="p1">“A gift from your seniors to their juniors.”</p>
        <p className="p2">
          “Change is the end result of all true learning.” <br></br>– Leo
          Buscaglia
        </p>
      </div>

      <div className="left-content">
        <div className="row">
          <Link to={"/"}>
            <img className="logo" src={logoBlack} alt="One-logo" />
          </Link>
        </div>
        {!verification && (
          <>
            <div className="title">Welcome Back</div>
            <div
              onClick={handleContinueWithGoogle}
              className="continue-with-google"
            >
              <img src={googleIcon} alt="Continue with google" />
              <div>Continue with google</div>
            </div>
            <div className="or">or</div>

            <form onSubmit={handleLogin}>
              <input
                className="input-feild"
                name="email"
                type="email"
                placeholder="Enter your email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {!forgotPass && (
                <div className="password-section">
                  <input
                    className="input-feild"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                  />
                </div>
              )}
              {!forgotPass && <button className="login-button">Log In</button>}
              {forgotPass && (
                <button
                  className="forgot-button"
                  onClick={() => {
                    handleForgotPass();
                  }}
                >
                  send reset mail
                </button>
              )}
            </form>
          </>
        )}
        {verification && (
          <div className="verification">
            <div className="title">
              Check your entered Email for a verification link
            </div>
            {/* <img src={email}></img> */}
            <p>
              An email with a verification link has been sent to you. Open the
              email and click on the link to verify your account.
            </p>
            <label>Your email:</label>
            <input type="text" value={`${currentUser.email}`} disabled></input>
            <p>
              Didn’t receive any email? Please check your spam folder or try to
              resend the email.
            </p>
            <button
              onClick={() => {
                ResendVerification();
              }}
            >
              Resend email
            </button>
          </div>
        )}
        <div className="center">
          <div className="dont-have-an-account">
            Don’t have an account?
            <Link className="signup-text-button" to={"/signup"}>
              Sign up
            </Link>
          </div>
          <button
            onClick={() => {
              setFP(true);
            }}
            style={{
              backgroundColor: "transparent",
              border: "none",
              marginLeft: "2%",
              marginTop: "-1%",
              color: "orange",
              textDecoration: "underline",
              fontWeight: "500",
            }}
          >
            Forgot Password?
          </button>
        </div>
        <div className="center">
          <div className="dont-have-an-account">
            Not a Student?
            <Link className="signup-text-button" to={"/educator"}>
              Click Here
            </Link>
          </div>
        </div>
      </div>
      <div className="right-content">
        <img
          className="login-display"
          src={loginDisplay}
          alt="Login display image"
        />
      </div>
    </div>
  );
}

export default Login;
