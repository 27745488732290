import React, { useContext } from "react";
import "./signup.scss";
import logoBlack from "../../assets/one_logo.png";
import tick from "../../assets/tickIconSignup.svg";
import tick2 from "../../assets/tickIconSignup2.svg";
import signupDisplay from "../../assets/signup-display.jpg";
import googleIcon from "../../assets/GoogleLogo.png";
import email from "../../assets/email_img.png";
import { useState, useEffect } from "react";
import Lod from "../../assets/Rolling-1s-200px.svg";
import { Link } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { auth, db, provider } from "../../services/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Student, studentConverter } from "../../models/UserModel";
import { useNavigate } from "react-router-dom";
import { Authcontext } from "../../contextProvider";

function Signup() {
  let navigate = useNavigate();
  const { currentUser } = useContext(Authcontext);
  const [showPassword, setShowPassword] = useState(false);
  const [Tick, setTick] = useState(false);
  const { loading, setLoading } = useContext(Authcontext);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCreateAccount = (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    createUserWithEmailAndPassword(auth, data["email"], data["password"])
      .then(async (userCredential) => {
        const user = new Student({
          id: userCredential.user.uid,
          fname: data["firstName"],
          lname: data["lastName"],
          email: data["email"],
          phone: data["phone"],
        });
        const ref = doc(db, "users", userCredential.user.uid).withConverter(
          studentConverter
        );
        await setDoc(ref, user);

        const userInfo = auth.currentUser;
        try {
          if (userInfo !== null) {
            await sendEmailVerification(userInfo);
            // console.log(userInfo);
            alert(
              "Verification email sent. Please verify your email and sign in again."
            );
            setLoading(false);
            signOut(auth);
          }
        } catch (error) {
          console.log(error);
          // Handle the error here, e.g., show an error message to the user.
        }

        try {
          await setDoc(doc(db, "user_data", userCredential.user.uid), {
            current_chap: [],
            enrolled_Chapters: [],
            id: userCredential.user.uid,
            completion: 0,
            testResults: [],
          }).then(() => {
            setLoading(false);
            if (!userInfo.emailVerified) {
              signOut(auth).then(() => {
                console.log("Signing out..");
              });
            } else if (userInfo.emailVerified) {
              navigate("/accountsetup");
            }
          });
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        alert(errorMessage);
        setLoading(false);
      });
  };

  const [isSignedIn, setIsSignedIn] = useState(false);

  const handleContinueWithGoogle = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const currentUser = result.user;

        if (!currentUser) {
          throw new Error("Failed to get user information.");
        }

        const docRef = doc(db, "users", currentUser.uid).withConverter(
          studentConverter
        );
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          const displayNameParts = currentUser.displayName
            ? currentUser.displayName.split(" ")
            : [];
          const user = new Student({
            id: currentUser.uid,
            fname: displayNameParts[0] || "",
            lname: displayNameParts.slice(1).join(" ") || "",
            email: currentUser.email || "",
            phone: currentUser.phoneNumber || "",
          });
          await setDoc(docRef, user);
        }

        try {
          await setDoc(doc(db, "user_data", currentUser.uid), {
            current_chap: [],
            enrolled_Chapters: [],
            id: currentUser.uid,
            completion: 0,
            testResults: [],
          }).then(() => {
            setLoading(false);
            navigate("/accountsetup");
          });
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData ? error.customData.email : "N/A";
        const credential = GoogleAuthProvider.credentialFromError(error);
        alert(errorMessage);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRedirectResult(auth)
      .then(async (result) => {
        if (result !== null) {
          setLoading(true);
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;
          const { displayName, email, phoneNumber, uid } = user;
          const [firstName, ...lastName] = displayName.split(" ");
          const lname = lastName.join(" ");

          const userRef = doc(db, "users", uid).withConverter(studentConverter);
          const docSnapshot = await getDoc(userRef);

          if (!docSnapshot.exists()) {
            // User does not exist in Firestore, create a new record
            const newUser = new Student({
              id: uid,
              fname: firstName,
              lname: lname,
              email: email,
              phone: phoneNumber,
            });

            // Set user data in 'users' collection
            await setDoc(userRef, newUser);

            // Set additional user data in 'user_data' collection
            await setDoc(doc(db, "user_data", uid), {
              current_chap: [],
              enrolled_Chapters: [],
              id: uid,
              completion: 0,
              testResults: [],
            });
          } else {
            alert("You already have an account!");
          }

          setLoading(false);
          navigate("/library");
          setIsSignedIn(true);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error(errorMessage);
      });
  }, []);

  const [verification, setVerification] = useState(false);
  const ResendVerification = () => {
    console.log("hi");
  };

  return (
    <div className="signup">
      {loading && (
        <div
          className="loading"
          style={{
            backgroundColor: "#000000ba",
            position: "fixed",
            zIndex: "3",
            width: "100vw",
            height: "100vh",
          }}
        >
          <img
            src={Lod}
            style={{
              position: "absolute",
              width: "20%",
              height: "15%",
              left: "40%",
              top: "40%",
            }}
          ></img>
        </div>
      )}
      <div className="left-content">
        <div className="card">
          <div className="row">
            <Link to={"/"}>
              <img className="logo" src={logoBlack} alt="One-logo" />
            </Link>

            <img
              className="login-display-image"
              src={signupDisplay}
              alt="Upgrade your learning experience"
            />
          </div>
          <div className="row1">
            <div className="title">Upgrade your learning experience.</div>
            <p className="subtitle">
              Discover world’s best way of learning through our platform.
              Animated Videos, Games, and Virtual Experiments Await!"
            </p>
          </div>
        </div>
      </div>
      <div className="right-content">
        {!verification && (
          <>
            <div className="title">Create an account</div>
            <div
              onClick={handleContinueWithGoogle}
              className="continue-with-google"
            >
              <img
                src={googleIcon}
                alt="Continue with google"
                className="continue-google-image"
              />
              <div className="continue-google-text">Continue with google</div>
            </div>
            <div className="or">or</div>

            <form onSubmit={handleCreateAccount}>
              <div className="name-section">
                <input
                  className="input-feild"
                  type="text"
                  name="firstName"
                  placeholder="First"
                />
                <input
                  className="input-feild"
                  type="text"
                  name="lastName"
                  placeholder="Last"
                />
              </div>
              <input
                className="input-feild"
                name="email"
                type="email"
                placeholder="Enter your email"
              />
              <div className="password-section">
                <input
                  className="input-feild"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                />
                {/* <button className='password-visiblity' type='button' onClick={togglePasswordVisibility}>
                  {showPassword ? 'Hide' : 'Show'}
                </button> */}
              </div>
              <input
                className="input-feild"
                name="phone"
                type="phone"
                placeholder="Enter your 10 digit mobile number"
              />
              <label class="terms">
                <input class="checkbox" type="checkbox" />
                {!Tick && (
                  <img
                    src={tick}
                    style={{ marginRight: "1%", height: "3vh" }}
                    onClick={() => {
                      setTick(true);
                    }}
                  ></img>
                )}
                {Tick && (
                  <img
                    src={tick2}
                    style={{ marginRight: "1%", height: "3vh" }}
                    onClick={() => {
                      setTick(false);
                    }}
                  ></img>
                )}
                <span>
                  Yes, I understand and agree to the terms and service
                </span>
              </label>
              <button className="create-account-button">Create account</button>
            </form>
          </>
        )}
        {verification && (
          <div className="verification">
            <div className="title">
              Check your entered Email for a verification link
            </div>
            <img src={email}></img>
            <p>
              An email with a verification link has been sent to you. Open the
              email and click on the link to verify your account.
            </p>
            <label>Your email:</label>
            <input type="text" value={`${currentUser.email}`} disabled></input>
            <p>
              Didn’t receive any email? Please check your spam folder or try to
              resend the email.
            </p>
            <button
              onClick={() => {
                ResendVerification();
              }}
            >
              Resend email
            </button>
          </div>
        )}
        <div className="row-container">
          {!verification && (
            <div className="have-account">
              Already have an account?
              <Link to={"/login"} className="login">
                &nbsp; Log In
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Signup;
